import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getStudPaymentTransactions } from './../../apis/services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import store from '../../redux/store';
import { DataView } from 'primereact/dataview';

const StudentWallet = () => {
    const { t } = useTranslation();
    const user = store.getState().app.user.user;
    const wallet = store.getState().app.user.wallet;
    const [transactions, setTransactions] = useState([]);
    const [filters, setFilters] = useState({

        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadStudPaymentTransactions();
    }, []);
    const loadStudPaymentTransactions = async () => {
        try {
            const res = await getStudPaymentTransactions();
            setTransactions(res.data.reacharges)
        } catch (error) {
            console.log(error);

        }
    }
    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;
        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });
        return <div className="grid grid-nogutter">{list}</div>;
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="col-12 mb-3" key={item.id}>
                <div className="card m-3">
                    <div className="p-3">
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('payment_id')}</span>
                            <span><strong>WR_{item.id}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('amount')}</span>
                            <span><strong>{item.amount.toFixed(3)}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('status')}</span>
                            <span><strong>{item.status}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('date')}</span>
                            <span><strong>{item.created_at}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('track_id')}:</span>
                            <span><strong>{item.track_id}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="">
            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <ul className="well-data-list mt-4">
                            <li className="d-flex jscb mb-2">
                                <span>{t('alias_name')}</span>
                                <strong>{user.alias_name}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('code')}</span>
                                <strong>{user.code}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('email')}</span>
                                <strong>{user.email}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('phone')}</span>
                                <strong>{user.phone}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <h3>{t('wallet')}</h3>
                        {
                            <h3>KWD {wallet.current_balance.toFixed(3)}</h3>
                        }
                        <div className='mb-2 mt-4 mb-4'>
                            <Link to="/wallet/recharge" className='link-btn'>{t('recharge_wallet')}</Link>
                        </div>

                    </div>
                </div>

                <div className="col-md-12">
                    <div className="glass-card p-3">
                        <h4>{t('payment_history')}</h4>
                        <div className='data-table mt-2 d-none d-lg-block d-md-block'>
                            <DataTable
                                value={transactions}
                                filters={filters}
                                paginator
                                rows={10}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                dataKey="id"
                                selectionMode="checkbox"
                                filterDisplay="menu"
                                emptyMessage={t('no_data')}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                header={
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <input className='form-control'
                                                placeholder='Search..'
                                                onChange={(e) => {
                                                    let _filters = { ...filters };
                                                    _filters['global'].value = e.target.value;
                                                    setFilters(_filters);
                                                }}
                                            />
                                        </div>

                                    </div>
                                }
                            >

                                <Column
                                    field="id"
                                    header={t('payment_id')}
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>WR_{r.id}</span>
                                        );
                                    }}
                                />
                                <Column
                                    field="amount"
                                    body={(r) => {
                                        return (
                                            <span>KWD {r.amount.toFixed(3)}</span>
                                        );
                                    }}
                                    header={t('amount')}
                                    sortable

                                />
                                <Column
                                    field="status"
                                    header={t('status')}
                                    sortable
                                />
                                <Column
                                    header={t('date')}
                                    field='created_at'
                                    sortField='created_at'
                                    sortable

                                />
                                <Column
                                    header={t('track_id')}
                                    field='track_id'
                                    sortField='track_id'
                                    sortable

                                />


                            </DataTable>
                        </div>
                        <div className="d-lg-none d-md-none">
                            <DataView
                                value={transactions}
                                listTemplate={listTemplate}
                                paginator rows={5}
                            />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default StudentWallet;