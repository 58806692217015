import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getQApendingApprovals } from './../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import HRsUnderQACheck from './tabs/qa/HRsUnderQACheck';
import HRsAnsUnderQACheck from './tabs/qa/HRsAnsUnderQACheck';
const QAPendingApprovals = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    useEffect(() => {
        loadQAHomeWorks();
    }, []);
    const loadQAHomeWorks = async () => {
        try {
            let data = {};
            const res = await getQApendingApprovals(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView>
                        <TabPanel header={t('hrs_to_approved')} leftIcon={<span className="material-symbols-outlined mx-2">schedule</span>}>
                            <HRsUnderQACheck assignments={assignments.hrsToBeApproved || []} />
                        </TabPanel>
                        <TabPanel header={t('answers_to_approve')} leftIcon={<span className="material-symbols-outlined mx-2">library_add_check</span>}>
                            <HRsAnsUnderQACheck assignments={assignments.ansToBeApproved || []} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default QAPendingApprovals;