import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addSupportTicketComment } from '../../apis/services';
import store from '../../redux/store';
const ViewSupportTicket = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const user = store.getState().app.user.user;
    const st = JSON.parse(location.state);
    const [stComment, setStComment] = useState();
    const stComments = st.st_comments === null ? [] : JSON.parse(st.st_comments);
    const handelAddSupportTicketComment = async () => {
        try {
            let comment = {
                userId: user.id,
                userName: user.alias_name,
                comment: stComment,
                date: new Date().toLocaleString(),

            }
            stComments.push(comment);
            let data = {
                comments: JSON.stringify(stComments),
                id: st.id,
            }

            await addSupportTicketComment(data);
            navigate(-1);
        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="p-3">
            <div className="row">
                <div className="col-md-7 mb-3">
                    <div className="glass-card">
                        <div className="d-flex jcsb p-3">
                            <span>{t('st_title')}</span>
                            <span><strong>{st.st_title}</strong></span>
                        </div>
                        <div className="d-flex jcsb p-3">
                            <span>{t('st_type')}</span>
                            <span><strong>{t(st.st_type)}</strong></span>
                        </div>
                        <div className="d-flex jcsb p-3">
                            <span>{t('hr_id')}</span>
                            <span><strong>{st.hr_id}</strong></span>
                        </div>
                        <div className="d-flex jcsb p-3">
                            <span>{t('created_at')}</span>
                            <span><strong>{st.created_at}</strong></span>
                        </div>
                        <div className="d-flex jcsb p-3">
                            <span>{t('st_desc')}</span>
                            <span><strong>{st.st_desc}</strong></span>
                        </div>
                    </div>
                </div>

                <div className="col-md-5 mb-3">
                    <div className="glass-card p-3">
                        <h6>{t('add_comment')}</h6>
                        <form className="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handelAddSupportTicketComment();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="comment" className='mb-1 required'>{t('comment')}</label>
                                <textarea rows={5} required className='form-control'
                                    value={stComment}
                                    onChange={(e) => {
                                        setStComment(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="from-group mt-2">
                                <Button label={t('add_comment')} className='p-btn' />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-12 m-3 mb-4">
                    {
                        user.role !== 'qa' &&
                        <Link 
                        state={location.state}
                        to="/support-tickets/edit" className='link-btn'>{t('edit_ticket')}</Link>
                    }
                </div>
                <div className="col-md-12 mb-3">
                    <div className="glass-card p-3">
                        <h3>{t('comments')}</h3>
                        {
                            st.st_comments !== null &&
                            <div>
                                {
                                    stComments.map((c, index) => {
                                        return (
                                            <div
                                                key={index}
                                            >
                                                <h6>- <strong>{c.userName}</strong> ({c.date})</h6>
                                                <p>{c.comment}</p>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewSupportTicket;