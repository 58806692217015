import React from "react";
const StudentHome = () => {
    const lang = localStorage.getItem('lang') || 'en';
	return (
		<div>
			<div className="card p-4">
				{
					lang === "en" &&
					<div className='en-content'>
						<h1>Welcome to TA-Pal: Student Dashboard</h1>
						<p>
							Below are the key rules, instructions, and reminders to help you make the most of your
							experience on TA-Pal. Please read them carefully to ensure smooth and professional use of the
							platform.
						</p>

						<h2>Instructions for Using TA-Pal</h2>
						<ol>
							<li>
								<strong>Submit Clear Requests:</strong>
								<ul>
									<li>Provide detailed and clear descriptions of the help you need, including relevant attachments or notes.</li>
									<li>Specify deadlines to help instructors understand your timeline.</li>
								</ul>
							</li>
							<li>
								<strong>Review Quotes Carefully:</strong>
								<ul>
									<li>Review quotes from instructors and consider their ratings and profiles before selecting the best fit for your needs.</li>
								</ul>
							</li>
							<li>
								<strong>Confirm Task Completion:</strong>
								<ul>
									<li>Once you receive the instructor’s response, review it thoroughly.</li>
									<li>Approve the response only if it meets your requirements, as this will release the payment.</li>
								</ul>
							</li>
							<li>
								<strong>Provide Feedback:</strong>
								<ul>
									<li>Rate the instructor’s service based on your experience to help maintain quality and transparency on the platform.</li>
								</ul>
							</li>
						</ol>

						<h2>Key Rules to Follow</h2>
						<ol>
							<li>
								<strong>Anonymity and Privacy:</strong>
								<ul>
									<li>Do not share your personal information (e.g., name, email, phone number) with instructors.</li>
									<li>All communication must take place exclusively through TA-Pal’s platform.</li>
								</ul>
							</li>
							<li>
								<strong>Prohibited Activities:</strong>
								<ul>
									<li>Do not request or suggest direct communication outside the platform.</li>
									<li>Avoid asking for unethical services, such as direct solutions to exams or violating academic integrity.</li>
								</ul>
							</li>
							<li>
								<strong>Respect Instructor Efforts:</strong>
								<ul>
									<li>Ensure your requests are respectful and professional.</li>
									<li>If revisions are needed, communicate clearly and politely.</li>
								</ul>
							</li>
						</ol>

						<h2>Reminders for a Positive Experience</h2>
						<ol>
							<li>
								<strong>Use the Service for Learning:</strong>
								<ul>
									<li>TA-Pal is designed to help you understand concepts and improve your academic skills. Avoid relying on it for direct answers.</li>
								</ul>
							</li>
							<li>
								<strong>Respect Deadlines:</strong>
								<ul>
									<li>Submit your requests early to allow instructors adequate time to provide quality assistance.</li>
								</ul>
							</li>
							<li>
								<strong>Build Trust and Accountability:</strong>
								<ul>
									<li>Providing constructive feedback and fair ratings ensures a better experience for all users.</li>
								</ul>
							</li>
							<li>
								<strong>Secure Payments:</strong>
								<ul>
									<li>Your payment is held securely until you approve the instructor’s response, ensuring fairness and satisfaction.</li>
								</ul>
							</li>
						</ol>

						<h2>Support and Assistance</h2>
						<p>
							If you encounter any issues or need help, please contact our support team at{" "}
							<a href="mailto:info@ta-pal.com">info@ta-pal.com</a>.
						</p>

						<p>
							Thank you for choosing TA-Pal. Together, we’re building a supportive and professional educational environment!
						</p>
					</div>
				}
				{
					lang === "ar" &&
					<div className="ar-content">
						<ArabicContent />
					</div>
				}
			</div>
		</div>
	);
};
const ArabicContent = () => {
	return (
		<div dir="rtl" lang="ar" >
			<h1>مرحبًا بك في لوحة التحكم للطلاب في TA-Pal</h1>
			<p>
				فيما يلي القواعد، التعليمات، والتذكيرات الأساسية لمساعدتك على تحقيق أقصى استفادة من تجربتك على TA-Pal. يُرجى قراءتها بعناية لضمان استخدام سلس واحترافي للمنصة.
			</p>
			<section>
				<h2>تعليمات استخدام TA-Pal</h2>
				<ol>
					<li>
						<strong>تقديم طلبات واضحة:</strong>
						<ul>
							<li>قدم وصفًا دقيقًا ومفصلاً للمساعدة التي تحتاجها، بما في ذلك المرفقات أو الملاحظات ذات الصلة.</li>
							<li>حدد المواعيد النهائية لمساعدة المدرسين على فهم احتياجاتك الزمنية.</li>
						</ul>
					</li>
					<li>
						<strong>مراجعة العروض بعناية:</strong>
						<ul>
							<li>قم بمراجعة العروض المقدمة من المدرسين، وضع في الاعتبار تقييماتهم وملفاتهم الشخصية قبل اختيار الأنسب لاحتياجاتك.</li>
						</ul>
					</li>
					<li>
						<strong>تأكيد إكمال المهمة:</strong>
						<ul>
							<li>بعد استلام رد المدرس، قم بمراجعته بدقة.</li>
							<li>وافق على الرد فقط إذا كان يلبي متطلباتك، حيث سيؤدي ذلك إلى تحرير الدفع.</li>
						</ul>
					</li>
					<li>
						<strong>تقديم الملاحظات:</strong>
						<ul>
							<li>قم بتقييم خدمة المدرس بناءً على تجربتك للمساعدة في الحفاظ على الجودة والشفافية على المنصة.</li>
						</ul>
					</li>
				</ol>
			</section>
			<section>
				<h2>القواعد الأساسية التي يجب اتباعها</h2>
				<ol>
					<li>
						<strong>السرية والخصوصية:</strong>
						<ul>
							<li>لا تشارك معلوماتك الشخصية (مثل الاسم، البريد الإلكتروني، رقم الهاتف) مع المدرسين.</li>
							<li>يجب أن يتم كل التواصل حصريًا من خلال منصة TA-Pal.</li>
						</ul>
					</li>
					<li>
						<strong>الأنشطة المحظورة:</strong>
						<ul>
							<li>لا تطلب أو تقترح التواصل المباشر خارج المنصة.</li>
							<li>تجنب طلب خدمات غير أخلاقية، مثل تقديم حلول مباشرة للاختبارات أو انتهاك النزاهة الأكاديمية.</li>
						</ul>
					</li>
					<li>
						<strong>احترام جهود المدرسين:</strong>
						<ul>
							<li>تأكد من أن طلباتك محترمة ومهنية.</li>
							<li>إذا كانت هناك حاجة إلى تعديلات، تواصل بوضوح وبأسلوب لائق.</li>
						</ul>
					</li>
				</ol>
			</section>
			<section>
				<h2>تذكيرات لضمان تجربة إيجابية</h2>
				<ol>
					<li>
						<strong>استخدام الخدمة للتعلم:</strong>
						<ul>
							<li>تم تصميم TA-Pal لمساعدتك على فهم المفاهيم وتحسين مهاراتك الأكاديمية. تجنب الاعتماد عليها للحصول على إجابات مباشرة.</li>
						</ul>
					</li>
					<li>
						<strong>احترام المواعيد النهائية:</strong>
						<ul>
							<li>قدم طلباتك مبكرًا لمنح المدرسين وقتًا كافيًا لتقديم مساعدة بجودة عالية.</li>
						</ul>
					</li>
					<li>
						<strong>بناء الثقة والمصداقية:</strong>
						<ul>
							<li>تقديم ملاحظات بناءة وتقييمات عادلة يضمن تجربة أفضل لجميع المستخدمين.</li>
						</ul>
					</li>
					<li>
						<strong>مدفوعات آمنة:</strong>
						<ul>
							<li>يتم الاحتفاظ بالدفع بأمان حتى توافق على رد المدرس، مما يضمن النزاهة والرضا.</li>
						</ul>
					</li>
				</ol>
			</section>
			<section>
				<h2>الدعم والمساعدة</h2>
				<p>
					إذا واجهت أي مشاكل أو كنت بحاجة إلى مساعدة، يرجى التواصل مع فريق الدعم على <a href="mailto:info@ta-pal.com">info@ta-pal.com</a>.
				</p>
			</section>
			<footer>
				<p>شكرًا لاختيارك TA-Pal. معًا نبني بيئة تعليمية داعمة ومهنية!</p>
			</footer>
		</div>
	);
};



export default StudentHome;
