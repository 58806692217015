import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstructorsAll } from '../../apis/services';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
const InstructorsListing = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    const [orgIns, setOrgIns] = useState([]);
    const [activeList, setActiveList] = useState({ show: false, list: [], type: '' });
    useEffect(() => {
        loadInstructors();
    }, []);
    const loadInstructors = async () => {
        try {
            const res = await getInstructorsAll();
            setIns(res.data.instructors);
            setOrgIns(res.data.instructors);
        } catch (error) {

        }
    }
    const searchInst = (key) =>{
        let filterered = orgIns.filter((e)=> (e.instructor.alias_name.toLowerCase().includes(key.toLowerCase()) || e.instructor.code.includes(key)));
        setIns(filterered);
    }
    return (
        <div className="p-3">
            <Modal
                show={activeList.show}
            >
                <div className="p-3">

                    {
                        activeList.type === "clg" &&
                        <div>
                            <h2>{t('colleges')}</h2>
                            <hr />
                            {
                                activeList.list.colleges.map((c, index) => {
                                    return (
                                        <div key={index}>
                                            <h5>{c.name}</h5>
                                            <hr />
                                        </div>
                                    );
                                })
                            }
                            <Button label={t('close')}
                                onClick={() => {
                                    setActiveList({ show: false, list: [], type: '' });
                                }}
                            />
                        </div>
                    }
                     {
                        activeList.type === "subs" &&
                        <div>
                            <h2>{t('colleges')}</h2>
                            <hr />
                            {
                                activeList.list.subjects.map((c, index) => {
                                    return (
                                        <div key={index}>
                                            <h5>{c.name}</h5>
                                            <hr />
                                        </div>
                                    );
                                })
                            }
                            <Button label={t('close')}
                                onClick={() => {
                                    setActiveList({ show: false, list: [], type: '' });
                                }}
                            />
                        </div>
                    }

                </div>
            </Modal>
            <div className="row mb-3">
                <div className="col-md-4">
                    <input type="text" className='form-control' placeholder={t('search')}
                        onChange={(e)=>{
                            searchInst(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row">
                {
                    ins.map((i, index) => {
                        return (
                            <div key={index} className='col-md-4 mb-3'>
                                <div className="glass-card">
                                    <div className="text-center">
                                        <span className="material-symbols-outlined mt-2"
                                            style={{
                                                fontSize: '5rem'
                                            }}
                                        >
                                            account_circle
                                        </span>
                                    </div>
                                    <div className="p-3">
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('name')}</h6>
                                            <h6>{i.instructor.alias_name}</h6>
                                        </div>
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('code')}</h6>
                                            <h6>{i.instructor.code}</h6>
                                        </div>
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('rating')}</h6>
                                            <Rating
                                                value={i.instructor.rating}
                                                readOnly
                                                cancel={false}
                                                disabled
                                            />
                                        </div>
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('reqs_answered')}</h6>
                                            <h6>{i.answeredCount}</h6>
                                        </div>
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('colleges')}</h6>
                                            <Button label={t('show')} className='p-btn px-3'
                                                onClick={(e) => {
                                                    setActiveList({ show: true, list: i, type: 'clg' });
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex jcsb aic mb-2">
                                            <h6>{t('subjects')}</h6>
                                            <Button label={t('show')} className='p-btn px-3'
                                            onClick={(e) => {
                                                setActiveList({ show: true, list: i, type: 'subs' });
                                            }}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        );
                    })
                }
            </div>
            {/* <div className="d-flex jcsb">

            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >

                    <Column
                        field="code"
                        header={t('Code')}
                        sortable
                    />
                    <Column
                        field="alias_name"
                        header={t('Alias Name')}
                        sortable
                    />
                    <Column
                        field="email"
                        header={t('Email')}
                        sortable
                    />
                    <Column
                        field="phone"
                        header={t('Phone')}
                        sortable
                    />
                     <Column
                        field="service_charge"
                        header={t('Service Charge')}
                        body={(r)=>{
                            return(
                                <span>{r.service_charge}%</span>
                            );
                        }}
                        sortable
                    />
                    <Column
                        field="rating"
                        header={t('Rating')}
                        sortable
                    />
                    <Column
                        header="Actions"
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    <Link
                                        state={JSON.stringify(row)}
                                        to='/instructors/view'
                                        className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link>
                                </div>
                            );
                        }}
                    />
                </DataTable>

            </div> */}
        </div>
    );
}

export default InstructorsListing;