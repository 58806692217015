import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInsPayoutRequests } from '../../apis/services';
const InsPayoutRequests = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    useEffect(() => {
        loadGetInsPayoutRequests();
    }, []);
    const loadGetInsPayoutRequests = async () => {
        try {
            const res = await getInsPayoutRequests();
            setIns(res.data.payouts);
        } catch (error) {

        }
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('manage_payouts')}</h4>
                </div>
                <div className='p-2'>
                    <Link to="/ins/payout-requests/create" className='link-btn'>{t('new_payment_request')}</Link>
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >

                    <Column
                        field="id"
                        header={t('pr_id')}
                        sortable
                    />
                    <Column
                        field="amount"
                        header={t('amount')}
                        body={(row) => <span>{row.amount.toFixed(3)}</span>}
                        sortable
                    />
                    <Column
                        field="created_on"
                        header={t('requsted_date')}
                        sortable
                    />
                    <Column
                        field="status"
                        header={t('status')}
                        sortable
                    />
                    <Column
                        field="processed_on"
                        header={t('processed_date')}
                        sortable
                    />
                    <Column
                        field="requested_currency"
                        header={t('requested_currency')}
                        sortable
                    />
                    {/* <Column
                        field="req_currency_amount"
                        header={t('requested_currency_Amount')}
                        sortable
                    /> */}
                    <Column
                        field="bank_account"
                        header={t('bank_account')}
                        body={(row) => <a href={row.bank_account} target='_blank'><span className="material-symbols-outlined">
                            open_in_new
                        </span></a>}
                        sortable
                    />
                    <Column
                        field="transfer_proof"
                        header={t('receipt')}
                        body={(row) => <a href={row.transfer_proof} target='_blank'><span className="material-symbols-outlined">
                            open_in_new
                        </span></a>}
                        sortable
                    />
                    <Column
                        field="released_amount"
                        header={t('released_amount')}
                        body={(row) => <span>{row.released_amount.toFixed(3)}</span>}
                        sortable
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default InsPayoutRequests;