import React from 'react';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { showApprove, canSendQuote, canApproveAns, canSeeAllQuotes, canAddAnswers, showAnswers, showAnswersLink, showArchive } from './../assignment';

const AssignmentActions = (props) => {
    const { t } = useTranslation();
    const hwData = props.hwData.homework;
    const hwQuotes = props.hwData.hwQuotes;
    const user = props.user;
    const role = user.role;
    return (
        <div className="p-3 d-flex aic flex-wrap ">
            {
                showApprove(hwData, role) &&
                <div className='mb-2'>
                    <Button label={t('approve')} className='mx-2 p-btn'
                        onClick={() => {
                            props.handleApproveHW();
                        }}
                    />
                    <Button label={t('reject')} severity='danger' className='mx-2 rounded-btn'
                        onClick={() => {
                            props.reject();
                        }}
                    />
                </div>
            }

            {
                showArchive(hwData, role) &&
                <div className='mb-2'>
                    <Button label={t("archive_hr")} className='p-btn mx-1' onClick={() => {
                        props.archiveHelpRequest();
                    }} />
                </div>
            }
            {
                canSendQuote(hwQuotes, user) &&
                <div className='mb-2'>
                    <Button label={t('send_quote')} className='p-btn' onClick={() => {
                        props.sendQuote();
                    }} />
                </div>
            }
            {
                canAddAnswers(hwData, user.id) &&
                <div className='mt-2 mb-2'>
                    <Link
                        state={hwData.id}
                        to="/assignments/add-answers"
                        className='link-btn mx-1'>{t('add_answers')}
                    </Link>
                </div>

            }
            {
                showAnswersLink(hwData, user) &&
                <div className='m-2 mb-2'>
                    <Link className='link-btn' to='/assignments/answers/view'
                        state={JSON.stringify({
                            preview: hwData.answer_preview,
                            answers: hwData.answers,
                            ans_desc: hwData.answer_desc,
                            rating: hwData.rating,
                            ans_approved:hwData.approved_answers,
                            rejected:hwData.rejected,
                            ids: {
                                ins: hwData.accepted_quote_from,
                                hr: hwData.id,
                            }
                        })}
                    >{t('view_answers')}</Link>
                </div>
            }
            {
                showAnswers(hwData, user.role) &&
                <div className='mt-3 mb-2'>
                    <Button label={t('view_answers')} className='p-btn' onClick={() => {
                        props.setShowHRAnswers();
                    }} />
                </div>
            }

        </div>
    );
}

export default AssignmentActions;