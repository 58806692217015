import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getInstPaymentTransactions } from './../../apis/services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { FilterMatchMode } from 'primereact/api';
import { useTranslation } from 'react-i18next';
import { getHRStatus } from '../assignments/assignment';
import { Button } from 'primereact/button';
import store from '../../redux/store';

const InstructorWallet = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const user = store.getState().app.user.user;
    const wallet = store.getState().app.user.wallet;
    const [transactions,setTransactions] = useState([]);
    const [filters, setFilters] = useState({

        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadInstPaymentTransactions();
    }, []);
    const loadInstPaymentTransactions = async () => {
        try {
            const res = await getInstPaymentTransactions();
            setTransactions(res.data.transactions);
        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="p-3">
            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <ul className="well-data-list mt-4">
                            <li className="d-flex jscb mb-2">
                                <span>{t('alias_name')}</span>
                                <strong>{user.alias_name}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('code')}</span>
                                <strong>{user.code}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('email')}</span>
                                <strong>{user.email}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('phone')}</span>
                                <strong>{user.phone}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('service_charge')}</span>
                                <strong>{user.service_charge}%</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <div className="glass-card p-3">
                        <h3>{t('wallet')}</h3>
                        <h4 style={{
                            marginTop:14
                        }}>{t('current_balance')}</h4>
                        {
                            <h3>KWD {wallet.current_balance.toFixed(3)}</h3>
                        }
                          <div className='mb-2 mt-4 mb-4'>
                            <Link to="/ins/payout-requests" className='link-btn'>{t('payout_requests')}</Link>
                        </div>

                    </div>
                </div>

                <div className="col-md-12 mt-3">
                    <div className="glass-card p-3">
                        <h4>{t('payment_history')}</h4>
                        <h6>{t('ins_payment_history_msg')}</h6>
                        <div>
                            <DataTable
                                value={transactions}
                                filters={filters}
                                paginator
                                rows={10}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                dataKey="id"
                                selectionMode="checkbox"
                                filterDisplay="menu"
                                emptyMessage={'No Help Requests.'}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                header={
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <input className='form-control'
                                                placeholder='Search..'
                                                onChange={(e) => {
                                                    let _filters = { ...filters };
                                                    _filters['global'].value = e.target.value;
                                                    setFilters(_filters);
                                                }}
                                            />
                                        </div>

                                    </div>
                                }
                            >

                                <Column
                                    field="id"
                                    header={t('payment_id')}
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>PT_00{r.id}</span>
                                        );
                                    }}
                                />
                                <Column
                                    field="amount"
                                    body={(r) => {
                                        return (
                                            <span>KWD {r.amount.toFixed(3)}</span>
                                        );
                                    }}
                                    header={t('amount')}
                                    sortable
                                />
                                 <Column
                                    field="net_amount"
                                    body={(r) => {
                                        return (
                                            <span>KWD {r.net_amount.toFixed(3)}</span>
                                        );
                                    }}
                                    header={t('net_amount')}
                                    sortable
                                />
                                <Column
                                    header={t('date')}
                                    field='accepted_at'
                                    sortField='accepted_at'
                                    sortable
                                />
                                <Column
                                    header={t('hr_id')}
                                    field='hw_id'
                                    sortField='hw_id'
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>HR_00{r.hw_id}</span>
                                        );
                                    }}
                                />
                                 <Column
                                    header={t('qid')}
                                    field='qid'
                                    sortField='qid'
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>QU_00{r.qid}</span>
                                        );
                                    }}
                                />
                                   <Column
                                    header={t('stud_id')}
                                    field='stud_id'
                                    sortField='stud_id'
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>ST_00{r.stud_id}</span>
                                        );
                                    }}
                                />

                              
                            </DataTable>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default InstructorWallet;