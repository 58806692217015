import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const AssignmentAttachments = (props) => {
    const attachments = JSON.parse(props.attachments);
    return (
        <div className='glass-card mb-2 p-2'>
            <Carousel
                className='file-sliders'
                showArrows
                infiniteLoop
            >
                {
                    attachments.map((a, index) => {
                        return (
                            <div key={index} className='p-2'>
                                {
                                    (a.type === "image/png" || a.type === "image/jpeg") &&
                                    <a href={a.uri} target="_blank" rel="noopener noreferrer"
                                        style={{
                                            display: "block",
                                        }}
                                    >
                                        <img src={a.uri}
                                            style={{
                                                maxHeight: 400,
                                                maxWidth: 400,
                                                width: '100%',
                                                textAlign: 'center',
                                                alignSelf: 'center'
                                            }}
                                        />
                                    </a>
                                }
                                {
                                    (a.type !== "image/png" && a.type !== "image/jpeg") &&
                                    <a href={a.uri} target="_blank" rel="noopener noreferrer">
                                        <div
                                            style={{
                                                border:"2px dotted #ccc",
                                                padding:'5rem',
                                                margin:50
                                            }}
                                        >
                                            <span className="material-symbols-outlined"
                                                style={{
                                                    fontSize: 80,
                                                }}
                                            >
                                                attach_file
                                            </span>
                                        </div>
                                    </a>
                                }
                            </div>
                        );
                    })
                }
            </Carousel>
        </div>
    );
}
export default AssignmentAttachments;