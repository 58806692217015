import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { resubmitHelpRequestAns } from '../../../apis/services';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useLocation, useNavigate } from 'react-router-dom';
const ResubmitAnswers = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const hw = location.state;
    const toastRef = useRef();
    const [answerDescription, setAnswerDescription] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [attachments, setAttachments] = useState([]);
    const [render, setRender] = useState(false);
    useEffect(() => {

    }, []);

    const handleResubmitHelpRequestAns = async () => {
        if (attachments.length > 0) {
            try {
                let formData = new FormData();
                formData.append('hw_id', hw.hr);
                formData.append('prevImg', previewImage);
                formData.append('answerDesc', answerDescription);
                for (const element of attachments) {
                    formData.append('files[]', element)
                }
               await resubmitHelpRequestAns(formData);
                toastRef.current.show({ severity: 'success', summary: 'Success', detail: "Answeres Added to the Request", life: 3000 });
                navigate('/ins/assignments-active');
            } catch (error) {
                toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'Unable to create. Please try again', life: 3000 });
            }
        }

    }
    return (
        <div className="p-3 glass-card">
            <Toast ref={toastRef} />
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('add_hr_answers')}</h4>
                    <h6>{t('dont_add_personal_data')}</h6>
                </div>
            </div>
            <hr />
            <form action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    handleResubmitHelpRequestAns();
                }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="answerDesc" className='mb-1 required'>{t('ans_desc')}</label>
                            <textarea name="answerDesc" id="answerDesc" value={answerDescription} className='form-control'
                                placeholder={t('ans_desc_msg')}
                                rows={5}
                                onChange={(e) => {
                                    setAnswerDescription(e.target.value);
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">
                            <label htmlFor="previewImage" className='mb-2 required'>{t('ans_preview_image')}</label>
                            <input type="file" name="previewImage" id="previewImage" className='form-control' accept='image/*'
                                onChange={(e) => {
                                    setPreviewImage(e.target.files[0]);
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h5 className='mt-3'>{t('upload_hr_ans')}</h5>
                        <h6>{t('max_files')}</h6>
                        <div className="row">
                            <div className="col-md-3">
                                <label htmlFor="fileInput">
                                    <div className='fileinput-wrapper'>
                                        <span className="material-symbols-outlined"
                                            style={{
                                                fontSize: 50
                                            }}
                                        >
                                            cloud_upload
                                        </span>
                                    </div>
                                </label>
                                <input type="file" name="fileInput" id="fileInput" style={{ display: 'none' }}
                                    onChange={(e) => {
                                        let existing = attachments;
                                        existing.push(e.target.files[0]);
                                        setAttachments(existing);
                                        setRender(!render);
                                    }}
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="attachments-preview mx-2">
                                    <div className='d-flex'>
                                        {attachments.map((a, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    style={{
                                                        margin: 10
                                                    }}
                                                >
                                                    {
                                                        (a.type === "image/png" || a.type === "image/jpeg") &&
                                                        <img src={URL.createObjectURL(a)} className='card'
                                                            style={{
                                                                borderRadius: 5,
                                                                width: 250,
                                                                height: 250
                                                            }}
                                                        />
                                                    }
                                                    {
                                                        (a.type !== "image/png" && a.type !== "image/jpeg") &&
                                                        <div
                                                            className='card'
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: 250,
                                                                height: 250,
                                                            }}
                                                        >
                                                            <span className="material-symbols-outlined"
                                                                style={{
                                                                    fontSize: 50
                                                                }}
                                                            >
                                                                attach_file
                                                            </span>
                                                        </div>
                                                    }

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <Button label={t('resubmit')} className='p-btn' />
                    </div>
                </div>
            </form>
        </div>

    );
}
export default ResubmitAnswers;