import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const InstMenu = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');
    const sizeToggler = useSelector((state) => state.app.showSidemenu);
    useEffect(() => {
        const path = location.pathname;
        setActivePath(path);
    }, [location.pathname, sizeToggler]);

    return (
        <div>
            <li className={`app-sidemenu-bar-item ${activePath.includes('assignments-available') ? 'active' : ''}`}>
                <Link to="/ins/assignments-available">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            menu_book
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('available_hrs')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('assignments-active') ? 'active' : ''}`}>
                <Link to="/ins/assignments-active">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            schedule
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('active_hrs')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('assignments-completed') ? 'active' : ''}`}>
                <Link to="/ins/assignments-completed">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            check_circle
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('completed_hrs')}
                        </span>
                    </span>
                </Link>
            </li>

            <li className={`app-sidemenu-bar-item ${activePath.includes('quotes') ? 'active' : ''}`}>
                <Link to="/ins/quotes">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            assignment
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('my_quotes')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('wallet') ? 'active' : ''}`}>
                <Link to="/instructor/wallet">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            wallet
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('wallet')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('payout-requests') ? 'active' : ''}`}>
                <Link to="/ins/payout-requests">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            sell
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('payouts')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                <Link to="/support-tickets">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            help
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('support_tickets')}
                        </span>
                    </span>
                </Link>
            </li>
        </div>
    );
};

export default InstMenu;