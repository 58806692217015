import { useEffect, useState } from "react";

const usePWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    const handler = (event) => {
      console.log(event);
      event.preventDefault();
      setDeferredPrompt(event);
      setInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);
    // return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installPWA = () => {
    console.log('****** CALL PWA INSTALL******');
    console.log(deferredPrompt);
    
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA installed");
        }
        setDeferredPrompt(null);
        setInstallable(false);
      });
    }
  };

  return { installable, installPWA };
};

export default usePWAInstallPrompt;
