
let baseUrl = process.env.REACT_APP_BASE_URL + 'api/v1';
// let baseUrl = 'https://ta-pal.com/api/v1';
const urls = {
    host: baseUrl,
    delete_record: baseUrl,
    login_url: baseUrl + '/user-login',
    get_user: baseUrl + '/logged-user',
    get_register_data: baseUrl + '/get-registeration-data',
    register_user: baseUrl + '/register-user',
    check_email: baseUrl + '/check-email',
    send_otp: baseUrl + '/send-otp',
    add_subject_category: baseUrl + '/add-subject-category',
    get_subject_categories: baseUrl + '/get-subject-categories',
    edit_subject_category: baseUrl + '/update-subject-category',
    add_subject: baseUrl + '/add-subject',
    get_subjects: baseUrl + '/get-subjects',
    edit_subject: baseUrl + '/update-subject',
    get_majors: baseUrl + '/get-majors',
    add_major: baseUrl + '/add-major',
    edit_major: baseUrl + '/update-major',
    get_colleges: baseUrl + '/get-colleges',
    add_college: baseUrl + '/add-college',
    edit_college: baseUrl + '/update-college',
    get_qa_assignments: baseUrl + '/get-qa-assignments',
    get_qa_pending_approvals: baseUrl + '/get-qa-pending-approvals',
    get_ins_assignments: baseUrl + '/instructor-homeworks',
    get_home_work_data: baseUrl + '/home-work-data',
    approve_home_work: baseUrl + '/approve-home-work',
    get_user_notifications: baseUrl + '/get-user-notifications',
    mark_notifications_as_read: baseUrl + '/mark-notifications-as-read',
    get_archived_hrs: baseUrl + '/get-archived-homeworks',
    get_app_lables: baseUrl + '/get-app-lables',
    add_app_lables: baseUrl + '/add-app-lables',
    edit_app_lables: baseUrl + '/edit-app-lables',
    delete_app_lables: baseUrl + '/delete-app-lables',
    get_all_instructors: baseUrl + '/get-instructors',
    get_all_instructors_student_lookup: baseUrl + '/get-all-instructors-student-lookup',
    get_instructors_profile: baseUrl + '/get-instructors-profile/',
    get_all_instructors_for_invite: baseUrl + '/get-instructors/for-invite',
    send_invitation_instructors: baseUrl + '/send-invitation-instructors',
    get_students: baseUrl + '/get-students',
    get_student_profile: baseUrl + '/get-student-profile/',
    get_countries: baseUrl + '/get-countries',
    add_country: baseUrl + '/add-country',
    edit_country: baseUrl + '/update-country',
    get_college_categories: baseUrl + '/get-collge-categories',
    add_college_category: baseUrl + '/add-college-category',
    edit_college_category: baseUrl + '/update-college-category',
    get_colleges_by_countries: baseUrl + '/get-colleges-by-countries',
    send_hw_quote: baseUrl + '/send-hw-quote',
    get_ins_hw_quotes: baseUrl + '/ins/hw-quotes',
    get_all_hw_quotes: baseUrl + '/all/hw-quotes',
    edit_ins_hw_quotes: baseUrl + '/ins/update-hw-quote',
    get_stud_assignments: baseUrl + '/student-homeworks',
    get_stud_hw_quotes: baseUrl + '/student/hr-quotes',
    get_stud_payment_transactions: baseUrl + '/user-wallet',
    get_inst_payment_transactions: baseUrl + '/user-wallet/instructor',
    get_payment_link: baseUrl + '/wallet/payment-link',
    get_payment_status: baseUrl + '/wallet/payment-status/',
    update_student_profile: baseUrl + '/student/update-profile',
    update_inst_profile: baseUrl + '/inst/update-profile',
    update_user_password: baseUrl + '/update/user-password',
    get_subjects_grouped_by_category: baseUrl + '/get-subjects-by-category',
    get_instructors_by_subject: baseUrl + '/get-instructors-by-subject',
    add_help_request: baseUrl + '/add-home-work',
    edit_help_request: baseUrl + '/edit-home-work',
    accept_quote: baseUrl + '/accept-quote',
    add_hw_answers: baseUrl + '/add-hw-answers',
    approve_home_work_answers: baseUrl + '/approve-home-work-answers',
    get_student_payouts: baseUrl + '/get-student-payouts',
    update_inst_service_charge: baseUrl + '/update-inst-service-charge',
    submit_hr_inst_rating: baseUrl + '/update-inst-rating',
    reject_help_request: baseUrl + '/reject-help-request',
    create_payout_requests: baseUrl + '/payout-requests/create',
    ins_payout_requests: baseUrl + '/ins/payout-requests',
    all_payout_requests: baseUrl + '/all/payout-requests',
    archive_hr: baseUrl + '/archive-homew-work',
    add_support_ticket: baseUrl + '/add-support-ticket',
    edit_support_ticket: baseUrl + '/edit-support-ticket',
    get_support_tickets: baseUrl + '/get-support-tickets',
    get_all_support_tickets: baseUrl + '/get-all-support-tickets',
    add_support_ticket_comment: baseUrl + '/support-tickets/add-comment',
    approve_hr_answer: baseUrl + '/approve-hr-answer',
    send_forgot_password_OTP: baseUrl + "/send-forgot-password-OTP",
    verify_OTP: baseUrl + '/verify-OTP',
    reset_password: baseUrl + '/reset-password',
    resubmit_hr_answers: baseUrl + "/resubmit-hr-answers",
    approve_payout_request: baseUrl + '/approve-payout-request',
    suspend_user: baseUrl + '/suspend-user',
    activate_user: baseUrl + '/activate-user',











}


export default urls;