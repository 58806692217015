import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getAllPayoutRequests, approvePayoutRequest } from '../../apis/services';
import { FilterMatchMode } from 'primereact/api';
import { Modal } from 'react-bootstrap';
import { InputNumber } from 'primereact/inputnumber';
const AllPayouts = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    const [amount, setAmount] = useState();
    const [receipt, setReceipt] = useState();
    const [showModal, setShowModal] = useState({ show: false, item: '' });
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadGetAllPayoutRequests();
    }, []);
    const loadGetAllPayoutRequests = async () => {
        try {
            const res = await getAllPayoutRequests();
            setIns(res.data.payouts);
        } catch (error) {

        }
    }
    const handleApprovePayoutRequest = async () => {
        try {
            const formData = new FormData();
            formData.append('amount', amount);
            formData.append('receipt', receipt);
            formData.append('req_id', showModal.item.id);
            await approvePayoutRequest(formData);
            setShowModal({ show: false, item: '' });
            loadGetAllPayoutRequests();
        } catch (error) {

        }
    }
    return (
        <div className="p-3 glass-card">
            <Modal
                show={showModal.show}
            >
                <div className="p-3">
                    <div className="d-flex aic jcsb">
                        <h5>{t('approve_request')}</h5>
                        <Button className='icon-btn'
                            onClick={() => {
                                setShowModal({ show: false, item: '' });
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <form action="" method="post"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleApprovePayoutRequest();
                        }}
                    >
                        <div className="form-group mb-2">
                            <label htmlFor="amount" className='mb-1 required'>{t('amount')}</label>
                            <InputNumber useGrouping={false} className='pr-input' required
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e.value);
                                }}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="proff" className='mb-1 required'>{t('receipt')}</label>
                            <input type="file" name="" id="" className='form-control' required
                                onChange={(e) => {
                                    setReceipt(e.target.files[0]);
                                }}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <Button type='submit' label={t('save')} className='p-btn' />
                        </div>
                    </form>
                </div>
            </Modal>
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('manage_payout_requests_from_inst')}</h4>
                </div>
                <div className='p-2'>
                    {/* <Button label='New Request' className='p-btn'></Button> */}
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    filters={filters}
                    header={
                        <div className='row'>
                            <div className="col-md-6">
                                <input className='form-control'
                                    placeholder={t('search')}
                                    onChange={(e) => {
                                        let _filters = { ...filters };
                                        _filters['global'].value = e.target.value;
                                        setFilters(_filters);
                                    }}
                                />
                            </div>
                        </div>
                    }
                >

                    <Column
                        field="id"
                        header={t('pr_id')}
                        sortable
                    />
                    <Column
                        field="inst_id"
                        header={t('ins_id')}
                        body={(row) => <span>INS_00{row.inst_id}</span>}
                        sortable
                    />
                    <Column
                        field="amount"
                        header={t('amount')}
                        body={(row) => <span>{row.amount.toFixed(3)}</span>}
                        sortable
                    />
                    <Column
                        field="created_on"
                        header={t('requsted_date')}
                        sortable
                    />
                    <Column
                        field="bank_account"
                        header={t('bank_account')}
                        body={(row) => <a href={row.bank_account} target='_blank'><span className="material-symbols-outlined">
                            open_in_new
                        </span></a>}
                        sortable
                    />
                    <Column
                        field="transfer_proof"
                        header={t('receipt')}
                        body={(row) => <a href={row.transfer_proof} target='_blank'><span className="material-symbols-outlined">
                            open_in_new
                        </span></a>}
                        sortable
                    />

                    <Column
                        field="processed_on"
                        header={t('processed_date')}
                        sortable
                    />
                    <Column
                        field="requested_currency"
                        header={t('requested_currency')}
                        sortable
                    />

                    <Column
                        field="released_amount"
                        header={t('released_amount')}
                        body={(row) => <span>{row.released_amount.toFixed(3)}</span>}
                        sortable
                    />
                    {/* <Column
                        field="req_currency_amount"
                        header={t('requested_currency_Amount')}
                        sortable
                    /> */}
                    <Column
                        header={t('actions')}
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    {
                                        row.status === 'sent' &&
                                        <Button
                                            onClick={() => {
                                                setShowModal({ show: true, item: row });
                                            }}
                                            className='icon-btn mx-1' severity='primary' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                check_circle
                                            </span>
                                        </Button>
                                    }
                                </div>
                            );
                        }}
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default AllPayouts;