import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { getSupportTickets } from '../../apis/services';
import { setShowDeleteDialog } from '../../redux/reducer';
import DeleteModalContent from '../../commons/DeleteModalContent';
import { Button } from 'primereact/button';
import store from '../../redux/store';
import { DataView } from 'primereact/dataview';
const ManageSupportTickets = () => {
    const { t } = useTranslation();
    const [supportTickets, setSupportTickets] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadGetSupportTickets();
    }, []);
    const loadGetSupportTickets = async () => {
        try {
            const res = await getSupportTickets();
            setSupportTickets(res.data.supportTickets);
        } catch (error) {

        }
    }
    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="col-12 mb-3" key={item.id}>
                <div className="card m-3">
                    <div className="p-3">
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('st_id')}</span>
                            <span><strong>{'ST_00'}{item.id}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('st_title')}</span>
                            <span><strong>{item.st_title}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('st_type')}</span>
                            <span><strong>{t(item.st_type)}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('created_at')}</span>
                            <span><strong>{item.created_at}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('hr_id')}</span>
                            <span><strong>{item.hr_id}</strong></span>
                        </div>
                        <div className='d-flex'>
                            <Link
                                to="/support-tickets/view"
                                state={JSON.stringify(item)}
                                className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                <span className="material-symbols-outlined">
                                    visibility
                                </span>
                            </Link>
                            <Button
                                onClick={() => {
                                    store.dispatch(setShowDeleteDialog({ show: true, url: '/support-tickets/delete/' + item.id }))
                                }}
                                className='icon-btn mx-1' severity='danger' id="edit-btn">
                                <span className="material-symbols-outlined">
                                    delete
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="glass-card p-3">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('manage_support_tickets')}</h4>
                </div>
                <div className='p-2 mb-2'>
                    <Link className='link-btn' to='/support-tickets/create'>{t('add_st')}</Link>
                </div>
            </div>
            <DeleteModalContent
                reload={() => {
                    loadGetSupportTickets();
                }}
            />
            <div className="data-table mt-2 d-none d-lg-block d-md-block">
                <DataTable
                    value={supportTickets}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    filters={filters}
                    header={
                        <div className='row'>
                            <div className="col-md-6">
                                <input className='form-control'
                                    placeholder={t('search')}
                                    onChange={(e) => {
                                        let _filters = { ...filters };
                                        _filters['global'].value = e.target.value;
                                        setFilters(_filters);
                                    }}
                                />
                            </div>

                        </div>
                    }
                >

                    <Column
                        field="id"
                        header={t('st_id')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'ST_00'}{row.id}</span>
                            )
                        }}
                    />
                    <Column
                        field="st_title"
                        header={t('st_title')}
                        sortable

                    />
                    <Column
                        field="st_type"
                        header={t('st_type')}
                        body={(row) => {
                            return (
                                <span>{t(row.st_type)}</span>
                            )
                        }}
                        sortable
                    />
                    <Column
                        field="hr_id"
                        header={t('hr_id')}
                        sortable
                    />
                    <Column
                        field="st_status"
                        header={t('status')}
                        sortable
                    />
                    <Column
                        field="created_at"
                        header={t('created_at')}
                        sortable
                    />
                    <Column
                        header={t('actions')}
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    <Link
                                        to="/support-tickets/view"
                                        state={JSON.stringify(row)}
                                        className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link>
                                    <Button
                                        onClick={() => {
                                            store.dispatch(setShowDeleteDialog({ show: true, url: '/support-tickets/delete/' + row.id }))
                                        }}
                                        className='icon-btn mx-1' severity='danger' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            delete
                                        </span>
                                    </Button>
                                </div>
                            )
                        }}
                    />
                </DataTable>
            </div>
            <div className="d-lg-none d-md-none">
                <DataView
                    value={supportTickets}
                    listTemplate={listTemplate}
                    paginator rows={5}
                />
            </div>
        </div>
    );
}
export default ManageSupportTickets;