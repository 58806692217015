import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendForgotPasswordOTP, verifyOTP, resetPassword } from './../../apis/services';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Modal } from 'react-bootstrap';
import { InputOtp } from 'primereact/inputotp';

const FargotPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [showOTP, setShowOTP] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPwdReset, setShowPwdReset] = useState();
    const [otp, setOtp] = useState();
    const toast = useRef(null);
    const handleSendForgotPasswordOTP = async () => {
        try {
            setLoading(true);
            let data = {
                email: username,
            }
            sendForgotPasswordOTP(data);
            setLoading(false);
            setShowOTP(true);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: t('request_failed'), detail: t('bad_credentials'), life: 1000 });
            setLoading(false);
        }
    }

    const verifyEnteredOTP = async () => {
        let data = {
            email: username,
            otp: otp
        }
        const res = await verifyOTP(data);
        if (!res.data.status) {
            toast.current.show({ severity: 'error', summary: t('bad_otp'), detail: t('bad_otp_msg'), life: 1000 });
        } else {
            setShowOTP(false);
            setShowPwdReset(true);
        }

    }
    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('passwords_not_matching'), life: 1000 });
        }
        else {
            let data = {
                email: username,
                password: password
            }
            await resetPassword(data);
            toast.current.show({ severity: 'success', summary: t('success'), detail: t('password_changed'), life: 1000 });
            setShowPwdReset(false);
            navigate('/');
        }
    }

    return (
        <div className="container-fluid">
            <Modal
                show={showOTP}
            >
                <div className="p-4">
                    <div className="d-flex jcsb">
                        <h3>{t('verify_otp')}</h3>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                setShowOTP(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <h6 className='mt-4'>{t('enter_otp')}</h6>
                    <div className='mt-4 mb-4'>
                        <InputOtp
                            length={6}
                            value={otp}
                            onChange={(e) => setOtp(e.value)}
                            integerOnly
                        />
                    </div>
                    <Button label={t("verify")} loading={loading} className='p-btn' id='register'
                        onClick={(e) => {
                            verifyEnteredOTP();
                        }}
                    />
                </div>
            </Modal>
            <Modal
                show={showPwdReset}
            >
                <div className="p-4">
                    <div className="d-flex jcsb">
                        <h3>{t('reset_password')}</h3>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                setShowPwdReset(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <div className='mt-4 mb-4'>
                        <div className="form-group">
                            <label htmlFor="newPassword" className='required mb-1'>{t('new_password')}</label>
                            <input type="password" name="newPassword" id="newPassword" required className='form-control'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword" className='required mb-1'>{t('confrim_password')}</label>
                            <input type="password" name="confirmPassword" id="confirmPassword" required className='form-control'
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <Button label={t("update")} loading={loading} className='p-btn' id='register'
                        onClick={(e) => {
                            handleResetPassword();
                        }}
                    />
                </div>
            </Modal>
            <Toast ref={toast}
                severity="danger"
            />
            <div className='row'>
                <div className="col-lg-6 col-md-6 d-none d-lg-block d-md-block p-0">
                    <div className='vh-center bg-img-vhicle'>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 d-lg-block d-md-block">
                    <div className="login-container">
                        <div className="text-center mt-5">
                            <img src="/logo-w.png" alt=""
                                style={{
                                    width: "10rem"
                                }}
                            />
                            <h3 className='mt-4'>TA-PAL</h3>
                            <p className="title">{t('forgot_password')}</p>

                        </div>
                        <div className='mt-3 padding-lg-4 lg-padding-4 p-lg-5 p-3'>
                            <form action="" method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSendForgotPasswordOTP();
                                }}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="username" className='required mb-1'>{t('email')}</label>
                                            <input type="text" name="username" id="username" className='form-control' required
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                                value={username}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Button label={t("continue")} loading={loading} type='submit' className='p-btn' id='loginButton' />
                                    </div>
                                </div>
                            </form>
                            <div className="col-12">
                                <div className="d-flex jcsb mt-3">
                                    <Link to="/" className='m-3'>{t('login')}</Link>
                                    <Link to="/regsiter/student" className='m-3'>{t('student_register')}</Link>
                                    <Link to="/regsiter/instructor" className='m-3'>{t('instructor_register')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FargotPassword;