import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getINSAssignmentQuotes } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import QuotesSent from './QuotesSent';
import AcceptedInsQuotes from './AcceptedInsQuotes';
const INSQuotes = () => {
    const { t } = useTranslation();
    const [hrQuotes, setHrQuotes] = useState([]);
    useEffect(() => {
        loadINSAssignmentQuotes();
    }, []);
    const loadINSAssignmentQuotes = async () => {
        try {
            const res = await getINSAssignmentQuotes();
            setHrQuotes(res.data.quotes);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView>
                        <TabPanel header={t('quotes_sent_by_you')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">send</span> }}>
                            <QuotesSent hrQuotes={hrQuotes}  loadINSAssignmentQuotes={()=>{ loadINSAssignmentQuotes()}} />
                        </TabPanel>
                        <TabPanel header={t('your_quotes_accepted_by_students')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">check_circle</span> }}>
                            <AcceptedInsQuotes hrQuotes={hrQuotes} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default INSQuotes;