import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { getUserNotifications, markNotificationsAsRead } from './../../apis/services';
import Select from 'react-select';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
const Notifications = (props) => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [action, setAction] = useState();
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    let actionOptions = [
        {
            value: 'read',
            label: t('mark_as_read')
        },
        {
            value: 'delete',
            label: t('delete_selected')
        }
    ];
    useEffect(() => {
        lodUserNotifications();
    }, []);
    const lodUserNotifications = async () => {
        try {
            const res = await getUserNotifications();
            setNotifications(res.data.notifications);
        } catch (error) {
            console.log(error.request);

        }
    }
    const handleNotificatiosAction = async () => {
        try {
            let data = {
                action: action.value,
                nids: selectedNotifications.map((n) => n.id)
            };
            await markNotificationsAsRead(data);
            lodUserNotifications();

        } catch (error) {

        }
    }

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;
        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });
        return <div className="grid grid-nogutter">{list}</div>;
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="col-12 mb-3" key={item.id}>
                <div className="card m-3">
                    <div className="p-3">
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('title')}:</span>
                            <span><strong>{item.title}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('message')}:</span>
                            <span><strong>{item.body}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('sent_at')}</span>
                            <span><strong>{t(item.sent_at)}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className='p-3 glass-card'>
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('notifications')}</h4>
                </div>
            </div>
            <div className='data-table mt-2 d-none d-lg-block d-md-block'>
                <DataTable
                    value={notifications}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    selection={selectedNotifications}
                    onSelectionChange={(e) => {
                        setSelectedNotifications(e.value);
                    }}
                    footer={
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleNotificatiosAction();
                            }}
                        >
                            <div className='row'>
                                <div className="col-md-4">
                                    <Select className='pr-input' required options={actionOptions}
                                        onChange={(e) => {
                                            setAction(e)
                                        }}
                                        placeholder={t('select')}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Button className='p-btn' label={t('apply')} type='submit' />
                                </div>
                            </div>
                        </form>
                    }
                >


                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

                    <Column
                        field="title"
                        header={t('title')}
                        sortable
                    />
                    <Column
                        field="body"
                        header={t('body')}
                        sortable
                    />
                    <Column
                        field="sent_at"
                        header={t('sent_at')}
                        sortable
                    />
                </DataTable>
            </div>
            <div className="d-lg-none d-md-none">
                <DataView
                    value={notifications}
                    listTemplate={listTemplate}
                    paginator rows={5}
                />
            </div>
        </div>
    );
}

export default Notifications;