import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllSupportTickets } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import FromStudents from './FromStudents';
import FromInstructors from './FromInstructors';
const AllSupportTickets = () => {
    const { t } = useTranslation();
    const [supportTickets, setSupportTickets] = useState([]);
    useEffect(() => {
        loadGetSupportTickets();
    }, []);
    const loadGetSupportTickets = async () => {
        try {
            const res = await getAllSupportTickets();
            setSupportTickets(res.data.supportTickets);
        } catch (error) {

        }
    }
    return (
        <div className="glass-card p-3">
            <TabView>
                <TabPanel header={t('from_students')} leftIcon={<span className="material-symbols-outlined mx-2">person_book</span>}>
                    <FromStudents tickets={supportTickets} />
                </TabPanel>
                <TabPanel header={t('from_instructors')} leftIcon={<span className="material-symbols-outlined mx-2">person</span>}>
                    <FromInstructors tickets={supportTickets} />
                </TabPanel>
            </TabView>
        </div>
    );
}
export default AllSupportTickets;