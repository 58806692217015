import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { getHRStatus } from '../../assignment';
const RequestsWithAnsRejected = (props) => {
    const { t } = useTranslation();
    const assignments = props.assignments.filter((hr) => getHRStatus(hr) === "ans_rejected");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    return (
        <div>
            <div className="">
                <div className="d-flex jcsb">
                    <div className='mb-2'>
                        <h6>{t('answered_and_ans_rejected')}</h6>
                    </div>
                </div>
                <div className="data-table mt-2">

                    <DataTable
                        value={assignments}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>

                            </div>
                        }
                    >

                        <Column
                            field="id"
                            header={t('hr_id')}
                            sortable
                            body={(row) => {
                                return (
                                    <span>{'HR_'}{row.id}</span>
                                )
                            }}
                        />
                        <Column
                            field="hw_title"
                            header={t('subject')}
                            sortable
                        />
                        <Column
                            field="hw_type"
                            header={t('type')}
                            sortable
                        />
                        <Column
                            header={t('urgent')}
                            sortField='urgent'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.urgent === 1 ? true : false} />
                                )
                            }}
                        />

                        {/* <Column
                            header={"Status"}
                            body={(r) => <span><strong>{getHRStatus(r)}</strong></span>}
                        /> */}
                        <Column
                            field="due_date"
                            header={t('due_date')}
                            sortable
                        />

                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.id}
                                            className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default RequestsWithAnsRejected;