import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Link } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { DataView } from 'primereact/dataview';

const StudAcceptedQuotes = (props) => {
    const { t } = useTranslation();
    const hrQuotes = props.hrQuotes.filter((i) => i.status === 1);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

  const listTemplate = (items) => {
        if (!items || items.length === 0) return null;
        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });
        return <div className="grid grid-nogutter">{list}</div>;
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="col-12 mb-3" key={item.id}>
                <div className="card m-3">
                    <div className="p-3">
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('hr_id')}</span>
                            <span><strong>HR_{item.hw_id}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('subject')}</span>
                            <span><strong>{item.hw_title}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('resp_time')}</span>
                            <span><strong>{item.res_time}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('quote_amount')}</span>
                            <span><strong>{item.quote_amount.toFixed(3)}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('date')}</span>
                            <span><strong>{item.quoteed_at}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('sent_by')}</span>
                            <span><strong>INS_00{item.ins_id}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('status')}</span>
                            <span><strong>{item.status === 1 ? t('accepted') : t('received')}</strong></span>
                        </div>
                        <div className='d-flex'>
                            <Link
                                to="/assignments/view"
                                state={item.hw_id}
                                className='link-icon-btn' >
                                <span className="material-symbols-outlined">
                                    visibility
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className="">
                <h6>{t('quotes_you_have_accepted')}.</h6>
                <div className='data-table mt-2 d-none d-lg-block d-md-block'>
                    <DataTable
                        value={hrQuotes}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    >

                        <Column
                            field="hw_id"
                            header={t('hr_id')}
                            sortable
                            body={(r) => {
                                return (
                                    <span>HR_{r.hw_id}</span>
                                );
                            }}
                        />
                        <Column
                            field="hw_title"
                            header={t('subject')}
                            sortable
                        />
                        <Column
                            field="quote_amount"
                            body={(r) => {
                                return (
                                    <span>KWD {r.quote_amount.toFixed(3)}</span>
                                );
                            }}
                            header={t('quote_amount')}
                            sortable
                        />

                        <Column
                            header={t('resp_time')}
                            field='res_time'
                        />
                        <Column
                            field="quoteed_at"
                            header={t('date')}
                            sortable
                        />
                        <Column
                            field="due_date"
                            header={t('due_date')}
                            body={(r) => {
                                return (
                                    <strong>{r.due_date}</strong>
                                );
                            }}
                            sortable
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.hw_id}
                                            className='link-icon-btn' >
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
                 <div className="d-lg-none d-md-none">
                                    <DataView
                                        value={hrQuotes}
                                        listTemplate={listTemplate}
                                        paginator rows={5}
                                    />
                                </div>
            </div>
        </div>
    );
}
export default StudAcceptedQuotes;