import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { editINSAssignmentQuotes } from '../../apis/services';
import { Modal } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { setShowDeleteDialog } from '../../redux/reducer';
import DeleteModalContent from '../../commons/DeleteModalContent';
import { FilterMatchMode } from 'primereact/api';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { quoteAmounts, respTimes } from './../assignments/assignment';
import { Badge } from 'primereact/badge';
const QuotesSent = (props) => {
    const { t } = useTranslation();
    const store = useStore();
    const user = store.getState().app.user.user;
    const hrQuotes = props.hrQuotes.filter((q) => q.status === 0)
    const [showSendQuote, setShowSendQuote] = useState({ show: false, id: '' });
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [respTime, setRespTime] = useState();
    const [quoteAmount, setQuoteAmount] = useState();  
    const handleEditQuote = async () => {
        try {
            let data = {
                quid: showSendQuote.id,
                respTime: respTime.label,
                quoteAmount: quoteAmount.value,
            }
            await editINSAssignmentQuotes(data);
            setShowSendQuote({ show: false, id: '' });
            props.loadINSAssignmentQuotes();

        } catch (error) {

        }
    }
    return (
        <div>
            <h6>{t('your_submiited_quotations_on_help_requests_yet_to_be_accepted_by_students')}</h6>
            <div className="">
                <DeleteModalContent
                    reload={() => {
                        props.loadINSAssignmentQuotes();
                    }}
                />
                <Modal
                    show={showSendQuote.show}
                >
                    <div className="p-3">
                        <div className="d-flex jcsb">
                            <h4>{t('update_quote')}</h4>
                            <Button className='icon-btn'
                                onClick={() => {
                                    setShowSendQuote(false);
                                }}
                            >
                                <span className="material-symbols-outlined">
                                    cancel
                                </span>
                            </Button>
                        </div>
                        <h6>{t('platform_fee_will_be_deducted')} -  {user.service_charge + '%'}</h6>
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleEditQuote();
                            }}
                        >
                            <div className="form-group mb-3">
                                <label htmlFor="ResponseTime" className='mb-1 required'>{t('resp_time')}</label>
                                <Select options={respTimes} required
                                    value={respTime}
                                    onChange={(e) => {
                                        setRespTime(e)
                                    }}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="quoteAmount" className='mb-1 required'>{t('quote_amount')}</label>
                                <Select options={quoteAmounts} value={quoteAmount}
                                    onChange={(e) => {
                                        setQuoteAmount(e);
                                    }}
                                    required
                                />

                            </div>
                            <div className="form-group mb-3">
                                <Button type='submit' className='p-btn' label={t('update')} />
                            </div>
                        </form>
                    </div>
                </Modal>
                <div className="data-table mt-2">
                    <DataTable
                        value={hrQuotes}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder='Search..'
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    >

                        <Column
                            field="hw_id"
                            header={t('hr_id')}
                            sortable
                            body={(r) => {
                                return (
                                    <span>HR_{r.hw_id}</span>
                                );
                            }}
                        />
                        <Column
                            field="quote_amount"
                            body={(r) => {
                                return (
                                    <span>KWD {r.quote_amount.toFixed(3)}</span>
                                );
                            }}
                            header={t('quote_amount')}
                            sortable
                        />

                        <Column
                            header={t('resp_time')}
                            field='res_time'

                        />
                        <Column
                            field="quoteed_at"
                            header={t('sent_on')}
                            sortable
                        />
                         <Column
                            field="due_date"
                            header={t('due_date')}
                            body={(r) => {
                                return (
                                    <strong>{r.due_date}</strong>
                                );
                            }}
                            sortable
                        />
                        <Column
                            field="status"
                            body={(r) => {
                                return (
                                    <span>{r.status === 0 ? "Sent" : "Accepted"}</span>
                                );
                            }}
                            header={t('status')}
                            sortable
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div>
                                        {row.status === 0 &&
                                            <div className='d-flex'>
                                                <Button
                                                    onClick={(e) => {
                                                        setRespTime({ label: row.res_time, value: row.res_time });
                                                        setQuoteAmount({ label: row.quote_amount, value: row.quote_amount });
                                                        setShowSendQuote({ show: true, id: row.id });
                                                    }}
                                                    className='icon-btn mx-1' severity='primary' id="edit-btn">
                                                    <span className="material-symbols-outlined">
                                                        edit
                                                    </span>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        store.dispatch(setShowDeleteDialog({ show: true, url: '/ins/quotes/delete/' + row.id }))
                                                    }}
                                                    className='icon-btn mx-1' severity='danger' id="edit-btn">
                                                    <span className="material-symbols-outlined">
                                                        delete
                                                    </span>
                                                </Button>
                                            </div>
                                        }
                                        {row.status === 1 &&
                                            <div className="d-flex">
                                                <Link
                                                    to="/assignments/view"
                                                    state={row.hw_id}
                                                    className='link-icon-btn' >
                                                    <span className="material-symbols-outlined">
                                                        visibility
                                                    </span>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
export default QuotesSent;