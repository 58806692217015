import React from 'react';
const QAHome = () => {
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <div className="">
            <div className="card p-4">
                {
                    lang === 'en' &&
                    <div className="en-content">
                        <h4><strong>Instructions for Quality Assurance (QA) Personnel</strong></h4>
                        <hr />
                        <p>
                            As a Quality Assurance person, your role is to ensure the integrity and compliance of all interactions on the TA-Pal platform. Below are the specific guidelines to follow when reviewing help requests and responses to maintain anonymity, adherence to platform rules, and overall quality:
                        </p>
                        <p>
                            <strong>
                                1. Ensure Anonymity
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>No Personal Information Sharing:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Review help requests and instructor responses to ensure no personal information (e.g., names, emails, phone numbers, or addresses) is included in the content.</span>
                                    </li>
                                    <li>
                                        <span>Flag and remove any text or images that reveal identifying details</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>No Direct Communication Channels:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Confirm that users do not share links or references to external platforms (e.g., social media, messaging apps, or email).</span>
                                    </li>
                                    <li>
                                        <span>Report and block any attempt to bypass the platform’s communication tools</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                2. Content Quality and Appropriateness
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>For Help Requests:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Ensure the request is clear, concise, and relevant to academic assistance.</span>
                                    </li>
                                    <li>
                                        <span>Confirm that the request does not violate ethical or academic integrity rules (e.g., asking for completed exams or submitting plagiarized material).</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>For Instructor Responses:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Verify that responses address the help request accurately and professionally.</span>
                                    </li>
                                    <li>
                                        <span>Check for completeness and clarity to ensure the student can understand and learn from the provided help.</span>
                                    </li>
                                    <li>
                                        <span>Review content for inappropriate language, plagiarism, or unprofessional conduct.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                3. Maintain Ethical Standards
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Adherence to Educational Integrity:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Ensure that the assistance provided supports the student’s learning process without completing work on their behalf.</span>
                                    </li>
                                    <li>
                                        <span>Confirm that instructors provide guidance, explanations, or frameworks rather than finalized solutions (e.g., solving a problem without explanation).</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Platform Rules Compliance:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Validate that all interactions comply with TA-Pal’s rules and policies.</span>
                                    </li>
                                    <li>
                                        <span>Escalate any content that may breach ethical or platform standards to the appropriate team for further review..</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                4. Data Privacy and Security
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Monitor Uploaded Files:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Review all uploaded files (e.g., images of problems or instructor responses) for metadata or hidden details that may inadvertently reveal identities.</span>
                                    </li>
                                    <li>
                                        <span>Remove any such details before allowing files to be sent or displayed..</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Data Retention:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Ensure that only necessary content is retained within the system, following the platform’s privacy and data protection policies</span>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                5. Feedback and Reporting
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Provide Feedback:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>If a help request or response is flagged for any issue, provide constructive feedback to the student or instructor for resubmission.</span>
                                    </li>
                                    <li>
                                        <span>Educate users on platform guidelines to prevent future violations.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Incident Reporting:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Document any repeated violations or attempts to bypass anonymity and report them to the management team..</span>
                                    </li>
                                    <li>
                                        <span>Keep a log of all flagged requests and responses for auditing purposes</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                6. Continuous Improvement
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <ul>
                                    <li>
                                        <span>Regularly review platform policies and ensure they are up to date with ethical and legal standards.</span>
                                    </li>
                                    <li>
                                        <span>Share observations and improvement suggestions with the team to enhance the quality control process.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>By diligently following these guidelines, you will help ensure that the TA-Pal platform remains a secure, anonymous, and professional space for academic assistance. If you encounter any unclear situations, escalate them to management for review.</p>
                    </div>
                }
                {
                    lang === 'ar' &&
                    <div className="ar-content">
                        <div>
                            <h4><strong>
                                إرشادات لموظف ضمان الجودة (QA)
                            </strong></h4>
                            <hr />
                            <p>
                                <strong>
                                    1. ضمان السرية
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <p className='mt-2'>
                                        <strong>عدم مشاركة المعلومات الشخصية</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>راجع طلبات المساعدة وردود المدرسين للتأكد من عدم وجود أي معلومات شخصية (مثل الأسماء، أو عناوين البريد الإلكتروني، أو أرقام الهاتف، أو العناوين) في المحتوى.</span>
                                        </li>
                                        <li>
                                            <span>حدد وأزل أي نصوص أو صور تكشف عن تفاصيل الهوية.</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='mt-2'>
                                        <strong>عدم مشاركة قنوات اتصال مباشرة</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تحقق من أن المستخدمين لا يشاركون روابط أو مراجع إلى منصات خارجية (مثل وسائل التواصل الاجتماعي أو تطبيقات المراسلة أو البريد الإلكتروني).</span>
                                        </li>
                                        <li>
                                            <span>أبلغ عن أي محاولات لتجاوز أدوات الاتصال الخاصة بالمنصة.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    2. جودة المحتوى وملاءمته
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <p className='mt-2'>
                                        <strong>بالنسبة لطلبات المساعدة</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تأكد من أن الطلب واضح وموجز ومرتبط بالمساعدة الأكاديمية.</span>
                                        </li>
                                        <li>
                                            <span>تحقق من أن الطلب لا ينتهك قواعد النزاهة الأكاديمية (مثل طلب اختبارات مكتملة أو تقديم مواد منسوخة).</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='mt-2'>
                                        <strong>بالنسبة لردود المدرسين</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تحقق من أن الردود تعالج الطلب بدقة واحترافية.</span>
                                        </li>
                                        <li>
                                            <span>تأكد من اكتمال الرد ووضوحه لضمان قدرة الطالب على فهم المحتوى والتعلم منه.</span>
                                        </li>
                                        <li>
                                            <span>راجع الردود للتحقق من خلوها من اللغة غير الملائمة أو النسخ أو السلوك غير المهني.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    3. الحفاظ على المعايير الأخلاقية
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <p className='mt-2'>
                                        <strong>الالتزام بالنزاهة الأكاديمية</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تأكد من أن المساعدة المقدمة تدعم عملية تعلم الطالب دون إنهاء العمل نيابة عنه.</span>
                                        </li>
                                        <li>
                                            <span>تحقق من أن المدرسين يقدمون التوجيه، التوضيحات، أو الإطار العام لحل المشكلة بدلًا من تقديم الحلول النهائية.</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='mt-2'>
                                        <strong>الامتثال لقواعد المنصة</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تأكد من أن جميع التفاعلات تتماشى مع قواعد وسياسات منصة TA-Pal.</span>
                                        </li>
                                        <li>
                                            <span>قم بتصعيد أي محتوى قد ينتهك الأخلاقيات أو معايير المنصة إلى الفريق المختص.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    4. حماية البيانات والخصوصية
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <p className='mt-2'>
                                        <strong>مراجعة الملفات المرفوعة</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>راجع جميع الملفات المرفوعة (مثل صور المشكلات أو ردود المدرسين) للتأكد من خلوها من بيانات وصفية أو تفاصيل مخفية قد تكشف عن الهويات عن غير قصد.</span>
                                        </li>
                                        <li>
                                            <span>أزل أي تفاصيل كهذه قبل السماح بمشاركة الملفات.</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='mt-2'>
                                        <strong>الاحتفاظ بالبيانات</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>تأكد من الاحتفاظ فقط بالمحتوى الضروري داخل النظام، وفقًا لسياسات خصوصية وحماية البيانات الخاصة بالمنصة.</span>
                                        </li>

                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    5. تقديم الملاحظات والتقارير
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <p className='mt-2'>
                                        <strong>تقديم الملاحظات</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>إذا تم وضع علامة على طلب مساعدة أو رد لأي مشكلة، قدم ملاحظات بنّاءة للطالب أو المدرس لإعادة الإرسال.</span>
                                        </li>
                                        <li>
                                            <span>قم بتوعية المستخدمين بإرشادات المنصة لتجنب الانتهاكات المستقبلية.</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p className='mt-2'>
                                        <strong>الإبلاغ عن الحوادث</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            <span>قم بتوثيق أي انتهاكات متكررة أو محاولات لتجاوز السرية، وأبلغ عنها إلى فريق الإدارة.</span>
                                        </li>
                                        <li>
                                            <span>احتفظ بسجل لجميع الطلبات والردود التي تم الإبلاغ عنها لأغراض التدقيق.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    6. التحسين المستمر
                                </strong>
                            </p>
                            <ul className='mx-4'>
                                <li>
                                    <ul>
                                        <li>
                                            <span>قم بمراجعة دورية لسياسات المنصة للتأكد من توافقها مع المعايير الأخلاقية والقانونية.</span>
                                        </li>
                                        <li>
                                            <span>شارك الملاحظات والاقتراحات مع الفريق لتحسين عملية مراقبة الجودة.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>باتباع هذه الإرشادات، ستساهم في ضمان أن تبقى منصة TA-Pal مكانًا آمنًا، وسريًا، ومهنيًا لتقديم المساعدة الأكاديمية. إذا واجهت أي مواقف غير واضحة، قم بتصعيدها إلى الإدارة للمراجعة.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
export default QAHome;