import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getQAAssignments } from '../../apis/services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { getHRStatus } from './assignment';
import Select from 'react-select';
const QAAssignments = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [assignments, setAssignments] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const statuses = [
        {
            label: t('all'),
            value: 'all',
        },
        {
            label: t('under_review'),
            value: 'under_review',
        },
        {
            label: t('qc_passed'),
            value: 'qc_passed',
        },
        {
            label: t('received_quotes'),
            value: 'received_quotes',
        },
        {
            label: t('quote_accepted'),
            value: 'quote_accepted',
        },
        {
            label: t('answered'),
            value: 'answered',
        },
        {
            label: t('awaiting_ans_approval'),
            value: 'awaiting_ans_approval',
        },
        {
            label: t('ans_rejected'),
            value: 'ans_rejected',
        },
        {
            label: t('paid'),
            value: 'paid',
        },
        {
            label: t('rejected'),
            value: 'rejected',
        },
        
    ];
    useEffect(() => {
        loadQAHomeWorks();
    }, []);
    const loadQAHomeWorks = async () => {
        try {
            let data = {};
            const res = await getQAAssignments(data);
            setAssignments(res.data.homeworks);
            setFiltered(res.data.homeworks);
        } catch (error) {

        }
    }
    const filterByStatus = (options) =>{
        let optionsValues = options.map((op)=> op.value);
        if(optionsValues.includes('all') || options.length === 0){
            setFiltered(assignments);
        }
        else{
            let filteredAssignments = assignments.filter((e)=> optionsValues.includes(getHRStatus(e)));
            setFiltered(filteredAssignments);
            
        }
        
    }
    return (

        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mb-2'>
                    <h5>{t('manage_hrs')}</h5>
                </div>
            </div>
            <div className="data-table mt-2">
                <DataTable
                    value={filtered}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    filters={filters}
                    header={
                        <div className='row'>
                            <div className="col-md-4">
                                <input className='form-control'
                                    placeholder={t('search')}
                                    onChange={(e) => {
                                        let _filters = { ...filters };
                                        _filters['global'].value = e.target.value;
                                        setFilters(_filters);
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <Select
                                    options={statuses}
                                    placeholder={t('status')}
                                    isMulti={true}
                                    onChange={(e)=>{
                                        filterByStatus(e);
                                    }}
                                />
                            </div>
                        </div>
                    }
                >
                    <Column
                        field="id"
                        header={t('hr_id')}
                        sortable
                        body={(row) => {
                            return (
                                <span>{'HR_'}{row.id}</span>
                            )
                        }}
                    />
                    <Column
                        field="hw_title"
                        header={t('subject')}
                        sortable
                    />
                    <Column
                        field="hw_type"
                        header={t('type')}
                        sortable
                    />
                    <Column
                        field="stud_id"
                        header={t('created_by')}
                        sortable
                        body={(row) => {
                            return (
                                <span>ST_00{row.stud_id}</span>
                            )
                        }}
                    />
                    <Column
                        header={t('status')}
                        body={(row) => {
                            return (
                                <strong>{t(getHRStatus(row))}</strong>
                            )
                        }}
                    />

                    <Column
                        header={t('urgent')}
                        sortField='urgent'
                        sortable
                        body={(row) => {
                            return (
                                <InputSwitch checked={row.urgent === 1 ? true : false} />
                            )
                        }}
                    />
                    <Column
                        field="due_date"
                        header={t('due_date')}
                        sortable
                    />
                    <Column
                        header={t('actions')}
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    <Link
                                        to="/assignments/view"
                                        state={row.id}
                                        className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link>
                                </div>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>

    );
}
export default QAAssignments;