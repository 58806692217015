import React from 'react';
import { getHRStatus } from '../assignment';
import { useTranslation } from 'react-i18next';
const AssignmentMetaData = (props) => {
    const hwData = props.hwData;
    const {t} = useTranslation();
    return (
        <div className="p2 glass-card"
            style={{
                backgroundColor: '#3F51B5',
                color: "#fff"
            }}
        >
            <div className="d-flex jcsb p-3">
                <span>{t('created_by')}</span>
                <span><strong>{hwData.alias_name}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('subject')}</span>
                <span><strong>{hwData.hw_title}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('type')}</span>
                <span><strong>{hwData.hw_type}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('due_date')}</span>
                <span><strong>{hwData.due_date}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('urgent')}</span>
                <span><strong>{hwData.urgent === 1 ? t('yes') : t('no')}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('status')}</span>
                <span
                    style={{
                        backgroundColor:"#FFF",
                        color:"#000",
                        padding:2,
                        borderRadius:3,
                    }}
                ><strong>{t(getHRStatus(hwData))}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('created_at')}</span>
                <span><strong>{hwData.created_at}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('cource_name_number')}</span>
                <span><strong>{hwData.cource_name_number}</strong></span>
            </div>
            <div className="d-flex jcsb p-3">
                <span>{t('course_instructor_name')}</span>
                <span><strong>{hwData.course_instructor_name}</strong></span>
            </div>
        </div>
    );
}
export default AssignmentMetaData;