import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { Link } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { getHRStatus } from './../../assignment';
import store from '../../../../redux/store';
import { DataView } from 'primereact/dataview';
const StudReqsUnderReview = (props) => {
    const { t } = useTranslation();
    const userStore = store.getState().app.user;
    const assignments = props.assignments.filter((hr) => getHRStatus(hr) === "under_review");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;
        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });
        return <div className="grid grid-nogutter">{list}</div>;
    };
    const itemTemplate = (item, index) => {
        return (
            <div className="col-12" key={item.id}>
                <div className="card mb-3">
                    <div className="p-3">
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('hr_id')}</span>
                            <span><strong>HR_{item.id}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('subject')}</span>
                            <span><strong>{item.hw_title}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('type')}</span>
                            <span><strong>{t(item.hw_type)}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('urgent')}</span>
                            <span><strong>{item.urgent === 1 ? t('yes') : t('no')}</strong></span>
                        </div>
                        <div className="d-flex aic jcsb mb-2">
                            <span>{t('due_date')}</span>
                            <span><strong>{item.due_date}</strong></span>
                        </div>

                        <div className='d-flex'>
                            <Link
                                to="/assignments/view"
                                state={item.id}
                                className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                <span className="material-symbols-outlined">
                                    visibility
                                </span>
                            </Link>
                            {
                                userStore.user.role === "student" &&
                                <Link
                                    to="/assignments/edit"
                                    state={JSON.stringify(item)}
                                    className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                    <span className="material-symbols-outlined">
                                        edit
                                    </span>
                                </Link>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className="">
                <div className="d-flex jcsb">
                    <div className='mb-2'>
                        <h6>{t('stud_hrs_inder_qc')}</h6>
                    </div>
                </div>
                <div className='data-table mt-2 d-none d-lg-block d-md-block'>
                    <DataTable
                        value={assignments}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        filters={filters}
                        header={
                            <div className='row'>
                                <div className="col-md-6">
                                    <input className='form-control'
                                        placeholder={t('search')}
                                        onChange={(e) => {
                                            let _filters = { ...filters };
                                            _filters['global'].value = e.target.value;
                                            setFilters(_filters);
                                        }}
                                    />
                                </div>

                            </div>
                        }
                    >

                        <Column
                            field="id"
                            header={t('hr_id')}
                            sortable
                            body={(row) => {
                                return (
                                    <span>{'HR_'}{row.id}</span>
                                )
                            }}
                        />
                        <Column
                            field="hw_title"
                            header={t('subject')}
                            sortable
                        />
                        <Column
                            field="hw_type"
                            header={t('type')}
                            sortable
                        />
                        <Column
                            header={t('urgent')}
                            sortField='urgent'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.urgent === 1 ? true : false} />
                                )
                            }}
                        />
                        <Column
                            field="due_date"
                            header={t('due_date')}
                            sortable
                        />

                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Link
                                            to="/assignments/view"
                                            state={row.id}
                                            className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                visibility
                                            </span>
                                        </Link>
                                        {
                                            userStore.user.role === "student" &&
                                            <Link
                                                to="/assignments/edit"
                                                state={JSON.stringify(row)}
                                                className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                                <span className="material-symbols-outlined">
                                                    edit
                                                </span>
                                            </Link>
                                        }

                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
                <div className="d-lg-none d-md-none">
                    <DataView
                        value={assignments}
                        listTemplate={listTemplate}
                        paginator rows={5}
                    />
                </div>
            </div>
        </div>
    );
}
export default StudReqsUnderReview;