import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getINSAssignments } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import InsQuotesReceivedRequests from './tabs/ins/InsQuotesReceivedRequests';
import InsQuoteAcceptedRequests from './tabs/ins/InsQuoteAcceptedRequests';
import InsAwaitingAnswersApprovalFromStudent from './tabs/ins/InsAwaitingAnswersApprovalFromStudent';
import InsAwaitingAnswersApproval from './tabs/ins/InsAwaitingAnswersApproval';
const InsActiveRequests = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    // const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTabIndex')) || 0);
    useEffect(() => {
        loadINSAssignments();
    }, []);
    const loadINSAssignments = async () => {
        try {
            let data = {

            };
            const res = await getINSAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView
                    // activeIndex={activeTab}
                    // onTabChange={(e)=>{
                    //     setActiveTab(e.index);
                    //     localStorage.setItem('activeTabIndex',e.index)
                    // }}
                    >
                        <TabPanel header={t('reqs_rec_quotes')} leftIcon={<span className="material-symbols-outlined mx-2">schedule</span>}>
                            <InsQuotesReceivedRequests assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('reqs_with_acc_quotes')} leftIcon={<span className="material-symbols-outlined mx-2">schedule</span>}>
                            <InsQuoteAcceptedRequests assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('ans_approval_from_qa')} leftIcon={<span className="material-symbols-outlined mx-2">schedule</span>}>
                            <InsAwaitingAnswersApproval assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('ans_approval_from_student')} leftIcon={<span className="material-symbols-outlined mx-2">schedule</span>}>
                            <InsAwaitingAnswersApprovalFromStudent assignments={assignments} />
                        </TabPanel>
                        
                        {/* <TabPanel header={t('InsAnsweredRequests')} leftIcon={<span className="material-symbols-outlined mx-2">check_circle</span>}>
                            <InsAnsweredRequests assignments={assignments} />
                        </TabPanel> */}
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default InsActiveRequests;