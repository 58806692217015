import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const QASidemenu = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');
    const sizeToggler = useSelector((state) => state.app.showSidemenu);
    useEffect(() => {
        const path = location.pathname;
        setActivePath(path);
    }, [location.pathname, sizeToggler]);

    return (
        <div >
            <li className={`app-sidemenu-bar-item ${activePath.includes('pending-approvals') ? 'active' : ''}`}>
                <Link to="/pending-approvals">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            schedule
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('pending_approvals')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('payout-requests') ? 'active' : ''}`}>
                <Link to="/payout-requests">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            sell
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('payouts')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                <Link to="/support-tickets/all">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            help
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('support_tickets')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                <Link to="/assignments">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            assignment
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('help_requests')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('instructors') ? 'active' : ''}`}>
                <Link to="/instructors">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            person
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('instructors')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('students') ? 'active' : ''}`}>
                <Link to="/students">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            person_book
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('students')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('subjects') ? 'active' : ''}`}>
                <Link to="/subjects">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            menu_book
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('subjects')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('colleges') ? 'active' : ''}`}>
                <Link to="/colleges">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            account_balance
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('colleges')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('majors') ? 'active' : ''}`}>
                <Link to="/majors">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            school
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('majors')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('archived') ? 'active' : ''}`}>
                <Link to="/archived">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            archive
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('archived')}
                        </span>
                    </span>
                </Link>
            </li>
            {/* <li className={`app-sidemenu-bar-item ${activePath.includes('app-lables') ? 'active' : ''}`}>
                <Link to="/app-lables">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            translate
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('App Lables')}
                        </span>
                    </span>
                </Link>
            </li> */}

        </div>
    );
};

export default QASidemenu;
