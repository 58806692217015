import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getINSAssignments } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import InstPreffered from './tabs/ins/InstPreffered';
import FromSubjects from './tabs/ins/FromSubjects';
import FromYourCollgeList from './tabs/ins/FromYourCollgeList';
import OtherHelpReqs from './tabs/ins/OtherHelpReqs';
const InsAvailableRequets = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    // const [activeTab, setActiveTab] = useState(parseInt(localStorage.getItem('activeTabIndex')) || 0);
    useEffect(() => {
        loadINSAssignments();
    }, []);
    const loadINSAssignments = async () => {
        try {
            let data = {

            };
            const res = await getINSAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView
                    // activeIndex={activeTab}
                    // onTabChange={(e)=>{
                    //     setActiveTab(e.index);
                    //     localStorage.setItem('activeTabIndex',e.index)
                    // }}
                    >
                        <TabPanel header={t('preferred_you')} leftIcon={<span className="material-symbols-outlined mx-2">account_circle</span>}>
                            <InstPreffered assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('from_subs_you_teach')} leftIcon={<span className="material-symbols-outlined mx-2">local_library</span>}>
                            <FromSubjects assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('from_your_clg')} leftIcon={<span className="material-symbols-outlined mx-2">school</span>}>
                            <FromYourCollgeList assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('others')} leftIcon={<span className="material-symbols-outlined mx-2">menu_book</span>}>
                            <OtherHelpReqs assignments={assignments} />
                        </TabPanel>
                        
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default InsAvailableRequets;