import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { getHomeWorkData, approveHomeWork, sendHWQuote, approveHomeWorkAnswers, rejectHelpRequest, archiveHelpRequest } from './../../apis/services';
import { Modal, Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import AssignmentMetaData from './commons/AssignmentMetaData';
import Select from 'react-select';
import { canSeeAllQuotes, quoteAmounts, respTimes } from './assignment';
import AssignmentQuotes from './commons/AssignmentQuotes';
import AssignmentAttachments from './commons/AssignmentAttachments';
import AssignmentActions from './commons/AssignmentActions';
import { useTranslation } from 'react-i18next';

const ViewAssignment = () => {
    const location = useLocation();
    const store = useStore();
    const { t } = useTranslation();
    const user = store.getState().app.user.user;
    const navigate = useNavigate();
    const hw_id = location.state;
    const [hwData, setHwData] = useState();
    const [hw, setHw] = useState();
    const [showSendQuote, setShowSendQuote] = useState(false);
    const [showHRAnswers, setShowHRAnswers] = useState(false);
    const [respTime, setRespTime] = useState();
    const [quoteAmount, setQuoteAmount] = useState();
    const [showRejection, setShowRejection] = useState(false);
    const [rejectionReason, setRejectionReason] = useState();
    const [rejectionComments, setRejectionComments] = useState();
    const rejectionReasons = [
        {
            value: 'personal_data',
            label: t('personal_data'),
        },
        {
            value: 'un_clear_files',
            label: t('un_clear_files'),
        },
        {
            value: 'stud_not_serious',
            label: t('stud_not_serious'),
        },
        {
            value: 'others',
            label: t('others'),
        }
    ];
    useEffect(() => {
        loadHomeWorkData();
    }, []);
    const loadHomeWorkData = async () => {
        try {
            let params = {
                id: hw_id,
            };
            const resp = await getHomeWorkData(params);
            setHwData(resp.data.homework);
            setHw(resp.data);
        } catch (error) {

        }
    }
    const handleApproveHW = async () => {
        try {
            let data = {
                comments: 'commemts',
                id: hwData.id,
                stud_id: hwData.stud_id,
            }
            await approveHomeWork(data);
            navigate(-1);
        } catch (error) {
            console.log(error);
        }
    }
    const handleSendQuote = async () => {
        try {
            let data = {
                comments: "comments",
                id: hwData.id,
                stud_id: hwData.stud_id,
                respTime: respTime.label,
                quoteAmount: quoteAmount.value,
                due_date: hwData.due_date,
                service_charge: user.service_charge,
            }
            await sendHWQuote(data);
            navigate('/ins/quotes');
        } catch (error) {

        }
    }
    const handleApproveHWAnswers = async () => {
        try {
            let data = {
                ins_id: hwData.accepted_quote_from,
                hw_id: hwData.id,
                rating: 3
            };
            await approveHomeWorkAnswers(data);
            setShowHRAnswers(false);
            navigate('/assignments/answers/view', {
                state: JSON.stringify({
                    preview: hwData.answer_preview,
                    answers: hwData.answers,
                    ans_desc: hwData.answer_desc,
                    rating: hwData.rating,
                    ids: {
                        ins: hwData.accepted_quote_from,
                        hr: hwData.id,
                    }
                })
            });
        } catch (error) {
            console.log(error);
        }
    }
    const handleRejectHW = async () => {
        try {
            let data = {
                rejectionReason: rejectionReason.value,
                rejectionComments: rejectionComments,
                hw_id: hwData.id,
                stud_id:hwData.stud_id,
                role:'student',
                type:'request',
            }
            await rejectHelpRequest(data);
            setShowRejection(false);
            navigate(-1);
        } catch (error) {

        }
    }
    const handelArchiveHelpRequest = async () => {
        try {
            let data = {
                id: hwData.id,
            }
            await archiveHelpRequest(data);
            navigate(-1);
        } catch (error) {

        }
    }
    
    return (
        <div>
            <div className="">
                {
                    (hwData !== undefined && hwData.rejected === 1) &&
                    <Alert variant='danger'>
                        <h6>{t('hr_rejected_due_to')} : <strong>{t(hwData.reajection_reason)}</strong></h6>
                    </Alert>
                }

                {
                    hwData === undefined &&
                    <div className="row">
                        <div className="col-md-4">
                            <div className="p2">
                                <Skeleton height={300} ></Skeleton>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="p2">
                                <Skeleton height={300} ></Skeleton>
                            </div>
                        </div>
                    </div>
                }
                {
                    hwData !== undefined &&
                    <div className="row">
                        <div className="col-md-7">
                            <AssignmentAttachments attachments={hwData.attachments} />
                        </div>
                        <div className="col-md-5">
                            <AssignmentMetaData hwData={hwData} />
                            <AssignmentActions hwData={hw} user={user}
                                reject={() => { setShowRejection(true) }}
                                handleApproveHW={() => { handleApproveHW() }}
                                archiveHelpRequest={() => { handelArchiveHelpRequest() }}
                                sendQuote={() => { setShowSendQuote(true) }}
                                setShowHRAnswers={() => { setShowHRAnswers(true) }}

                            />
                        </div>
                        <Modal
                            show={showSendQuote}
                        >
                            <div className="p-3">
                                <div className="d-flex jcsb">
                                    <h4>{t('send_quote')}</h4>
                                    <Button className='icon-btn'
                                        onClick={() => {
                                            setShowSendQuote(false);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            cancel
                                        </span>
                                    </Button>
                                </div>
                                <h6>{t('platform_fee_will_be_deducted')} -  {user.service_charge + '%'}</h6>
                                <form action=""
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSendQuote();
                                    }}
                                >
                                    <div className="form-group mb-3">
                                        <label htmlFor="ResponseTime" className='mb-1 required'>{t('resp_time')}</label>
                                        <Select options={respTimes} required
                                            placeholder={t('select')}
                                            value={respTime}
                                            onChange={(e) => {
                                                setRespTime(e)
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="quoteAmount" className='mb-1 required'>{t('quote_amount')}</label>
                                        <Select options={quoteAmounts}
                                            placeholder={t('select')}
                                            value={quoteAmount}
                                            onChange={(e) => {
                                                setQuoteAmount(e);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Button type='submit' className='p-btn' label={t('send_quote')} />
                                    </div>
                                </form>
                            </div>
                        </Modal>
                        <Modal
                            show={showHRAnswers}
                            size='lg'
                        >
                            <div className="p-3">
                                <div className="d-flex jcsb">
                                    <h3>View Answers</h3>
                                    <Button className='icon-btn'
                                        onClick={() => {
                                            setShowHRAnswers(false);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            cancel
                                        </span>
                                    </Button>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <div className="card p-2">
                                            <h6>{t('total_files')}</h6>
                                            <h5>{JSON.parse(hwData.attachments).length}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card p-2">
                                            <h6>Answer Description</h6>
                                            <h5><strong>{hwData.answer_desc}</strong></h5>
                                        </div>
                                    </div>
                                </div>
                                {
                                    hwData.answer_preview !== null &&
                                    <div className="card m-3 p-1">
                                        <img src={JSON.parse(hwData.answer_preview).uri}
                                            style={{
                                                width: '100%',
                                                filter: 'blur(3px)'
                                            }}
                                        />
                                    </div>
                                }

                                <Button label={t('approve_answers')} className='p-btn mb-2'
                                    onClick={() => {
                                        handleApproveHWAnswers();
                                    }}
                                />
                                <h6>*By Approving answers, quoted amount will be transferred to the Instructor.</h6>
                            </div>
                        </Modal>
                        <Modal
                            show={showRejection}
                        >
                            <div className="p-3">
                                <div className="d-flex jcsb">
                                    <h4>{t('reject_help_request')}</h4>
                                    <Button className='icon-btn'
                                        onClick={() => {
                                            setShowRejection(false);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            cancel
                                        </span>
                                    </Button>
                                </div>
                                <div className="p-2">
                                    <form action=""
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleRejectHW();
                                        }}
                                    >
                                        <div className="form-group">
                                            <label htmlFor="reason" className='mb-1 required'>{t('rejection_reason')}</label>
                                            <Select className='pr-input' required
                                                options={rejectionReasons}
                                                placeholder={t('select')}
                                                onChange={(e) => {
                                                    setRejectionReason(e)
                                                }}
                                                value={rejectionReason}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="comments" className='mb-1 required'>{t('comments')}</label>
                                            <textarea name="comments" id="comments" className='form-control'
                                                value={rejectionComments}
                                                onChange={(e) => {
                                                    setRejectionComments(e.target.value);
                                                }}
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="form-group mt-3">
                                            <Button label={t('reject')} className='p-btn ' />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                        <div className="col-md-12">
                            {
                                canSeeAllQuotes(user.role) &&
                                <div className="p-2 glass-card">
                                    {
                                        <AssignmentQuotes
                                            hwQuotes={hw.hwQuotes}
                                            hwData={hwData}
                                            role={user.role}
                                            loadHomeWorkData={() => { loadHomeWorkData() }}
                                            hasAcceptedQuote={hw.homework.has_quote}
                                        />
                                    }
                                </div>
                            }

                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
export default ViewAssignment;