import urls from "./urls";
import axios from "./axios";
export const checkLogin = async (token) => {
    try {
        const response = await axios({
            method: 'POST',
            url: urls.api_server + urls.check_token,
            params: {
                token: token,
            }
        });
        return response;
    } catch (error) {
        return false;
    }

}
export const startLogin = async (user) => {
    try {
        let response = await axios({
            method: 'POST',
            data: user,
            url: urls.login_url,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getUserData = async (token) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_user,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const deleteRecord = async (url) => {
    try {
        let response = await axios({
            method: 'DELETE',
            url: urls.delete_record + url,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const storeSubject = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.add_subject,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const updateSubject = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.edit_subject,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getSubjects = async (token) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_subjects,
        });
        return response;
    } catch (error) {
        return error;
    }
}




export const getSubjectCategories = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_subject_categories,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeSubjectCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_subject_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateSubjectCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_subject_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getMajors = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_majors,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeMajor = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_major,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateMajor = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_major,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getColleges = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_colleges,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeCollege = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_college,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateCollege = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_college,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getQApendingApprovals = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_qa_pending_approvals,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getQAAssignments = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_qa_assignments,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getINSAssignments = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_ins_assignments,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getHomeWorkData = async (params) => {
    let response = await axios({
        method: 'GET',
        url: urls.get_home_work_data,
        params: params,
    });
    return response;
}
export const approveHomeWork = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.approve_home_work,
        data: data,
    });
    return response;
}


export const getUserNotifications = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_user_notifications,
    });
    return response;
}
export const getAppLables = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_app_lables,
    });
    return response;
}
export const addAppLables = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.add_app_lables_app_lables,
        data: data
    });
    return response;
}
export const editAppLables = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.edit_app_lables,
        data: data
    });
    return response;
}
export const getRegisterData = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_register_data,
    });
    return response;
}
export const registerUser = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.register_user,
    });
    return response;
}

export const checkEmail = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.check_email,
    });
    return response;
}
export const sendOTP = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.send_otp,
    });
    return response;
}
export const markNotificationsAsRead = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.mark_notifications_as_read,
    });
    return response;
}

export const getInstructors = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_all_instructors,
    });
    return response;
}
export const getInstructorsAll = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_all_instructors_student_lookup,
    });
    return response;
}
export const getInstructorsForInvite = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_all_instructors_for_invite,
    });
    return response;
}
export const sendInviationForInstructors = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.send_invitation_instructors,
    });
    return response;
}

export const getStudents = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_students,
    });
    return response;
}


export const getCountries = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_countries,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const storeCountry = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_country,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateCountry = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_country,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getCollegeCategories = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            params: params,
            url: urls.get_college_categories,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const addCollegeCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_college_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateCollegeCategory = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_college_category,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getCollegesByCountries = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_colleges_by_countries,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getInstructorsProfile = async (uid) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_instructors_profile + uid,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getStudentProfile = async (uid) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_student_profile + uid,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const sendHWQuote = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.send_hw_quote,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getINSAssignmentQuotes = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_ins_hw_quotes,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getAllAssignmentQuotes = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_all_hw_quotes,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const editINSAssignmentQuotes = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_ins_hw_quotes,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getArchivedAssignments = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_archived_hrs,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getStudAssignments = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.get_stud_assignments,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getStudAssignmentQuotes = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_stud_hw_quotes,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getStudPaymentTransactions = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_stud_payment_transactions,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getInstPaymentTransactions = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_inst_payment_transactions,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getPaymentLink = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.get_payment_link,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getPaymentStatus = async (cid) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_payment_status + cid,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateStudentProfile = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.update_student_profile,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateInstProfile = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.update_inst_profile,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const updateUserPassword = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.update_user_password,
            data: data
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getSubjectsGroupedByCategory = async () => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_subjects_grouped_by_category,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const getInstructorsBySubject = async (params) => {
    try {
        let response = await axios({
            method: 'GET',
            url: urls.get_instructors_by_subject,
            params: params
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const addHelpRequest = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.add_help_request,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const editHelpRequest = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            url: urls.edit_help_request,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}


export const acceptQuote = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.accept_quote,
        data: data,
    });
    return response;
}
export const addHelpReqAnswers = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.add_hw_answers,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

export const approveHomeWorkAnswers = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.approve_home_work_answers,
        data: data,
    });
    return response;
}
export const rejectHelpRequest = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.reject_help_request,
        data: data,
    });
    return response;
}
export const getStudentPayouts = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_student_payouts,

    });
    return response;
}

export const updateServiceCharge = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.update_inst_service_charge,

    });
    return response;
}

export const submitHRInstRating = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        url: urls.submit_hr_inst_rating,

    });
    return response;
}

export const createPayoutRequest = async (data) => {
    let response = await axios({
        method: 'POST',
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: urls.create_payout_requests,

    });
    return response;
}
export const getInsPayoutRequests = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.ins_payout_requests,

    });
    return response;
}
export const getAllPayoutRequests = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.all_payout_requests,

    });
    return response;
}
export const archiveHelpRequest = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.archive_hr,
        data: data

    });
    return response;
}

export const addSupportTicket = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.add_support_ticket,
        data: data
    });
    return response;
}

export const editSupportTicket = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.edit_support_ticket,
        data: data
    });
    return response;
}

export const getSupportTickets = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_support_tickets,
    });
    return response;
}

export const getAllSupportTickets = async () => {
    let response = await axios({
        method: 'GET',
        url: urls.get_all_support_tickets,
    });
    return response;
}

export const addSupportTicketComment = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.add_support_ticket_comment,
        data: data
    });
    return response;
}
export const approveHRAnswer = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.approve_hr_answer,
        data: data
    });
    return response;
}

export const sendForgotPasswordOTP = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.send_forgot_password_OTP,
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const verifyOTP = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.verify_OTP,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const resetPassword = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.reset_password,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const resubmitHelpRequestAns = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.resubmit_hr_answers,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}
export const approvePayoutRequest = async (data) => {
    let response = await axios({
        method: 'POST',
        url: urls.approve_payout_request,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

export const suspendUser = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.suspend_user,
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const activateUser = async (data) => {
    try {
        let response = await axios({
            method: 'POST',
            data: data,
            url: urls.activate_user,
        });
        return response;
    } catch (error) {
        return error;
    }
}





