export const quoteAmounts = [
    { value: 5, label: "5KD" },
    { value: 10, label: "10KD" },
    { value: 15, label: "15KD" },
    { value: 20, label: "20KD" },
    { value: 30, label: "30KD" },
    { value: 40, label: "40KD" },
    { value: 50, label: "50KD" },
    { value: 75, label: "75KD" },
    { value: 100, label: "100KD" },
    { value: 125, label: "125KD" },
    { value: 150, label: "150KD" },
    { value: 200, label: "200KD" },
    { value: 250, label: "250KD" },
    { value: 300, label: "300KD" },
    { value: 350, label: "350KD" },
    { value: 400, label: "400KD" },
    { value: 450, label: "450KD" },
    { value: 500, label: "500KD" },
    { value: 600, label: "600KD" },
    { value: 700, label: "700KD" },
    { value: 800, label: "800KD" },
    { value: 900, label: "900KD" }
];
export const respTimes = [
    {
        value: '1 Hr',
        label: '1 Hr',
    },
    {
        value: '6 Hrs',
        label: '6 Hrs',
    },
    {
        value: '24 Hrs',
        label: '24 Hrs',
    },
    {
        value: '2 Days',
        label: '2 Days',
    },
    {
        value: '3 Days',
        label: '3 Days',
    },
    {
        value: '1 Week',
        label: '1 Week',
    },
    {
        value: '2 Weeks',
        label: '2 Weeks',
    },
    {
        value: '1 Month',
        label: '1 Month',
    },
    {
        value: '2 Months',
        label: '2 Months',
    }
];

export const getHRStatus = (hr) => {
    if (hr.rejected === 1 && hr.approved_answers === 0 && hr.approved === 1) {
        return "ans_rejected"
    }
    if (hr.rejected === 1 && hr.approved_answers === 0 && hr.approved === 0) {
        return "rejected"
    }
    if (hr.paid === 1) {
        return "paid"
    }
    else if (hr.answered === 1 && hr.approved_answers === 1) {
        return "answered"
    }
    else if (hr.answered === 1 && hr.approved_answers === 0) {
        return "awaiting_ans_approval"
    }
    else if (hr.has_quote === 1) {
        return "quote_accepted"
    }
    else if (hr.received_quotes !== 0) {
        return "received_quotes";
    }
    else if (hr.approved === 1) {
        return "qc_passed"
    }
    else if (hr.approved === 0) {
        return "under_review"
    }
}

export const showApprove = (hr, role) => {
    return !!(hr.approved === 0 && role === 'qa' && hr.rejected === 0);
}
export const canSeeAllQuotes = (role) => {
    return !!(role === 'qa' || role === 'student');
}
export const canSendQuote = (quotes, user) => {

    let userHasQuote = quotes.some((q) => q.ins_id === user.id);
    return !!(user.role === 'teacher' && !userHasQuote);
}
export const canAddAnswers = (hr, uid) => {
    return !!(hr.accepted_quote_from === uid && hr.answered === 0);
}
export const showAnswers = (hr, role) => {
    return !!(hr.answered === 1 && hr.paid === 0 && role === "student" && hr.approved_answers === 1);
}
export const showAnswersLink = (hr, user) => {
    return !!((hr.answered === 1 && hr.paid === 1) || (user.id === hr.accepted_quote_from && hr.answered === 1) || (hr.answered === 1 && user.role === 'qa'));
}

export const showArchive = (hr, role) => {
    return !!(((hr.answered === 1 && hr.paid === 1) ||
        (hr.approved === 0 || hr.rejected === 1)) &&
        role === "student");
}

export const canApproveAns = (hr, user) => {
    return !!(hr.answered === 1 && hr.paid === 0 && user.role === "qa");
}
