import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getINSAssignments } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import RequestsPaidForInst from './tabs/ins/RequestsPaidForInst';
import RequestsWithAnsRejected from './tabs/ins/RequestsWithAnsRejected';
const InsCompletedRequets = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    useEffect(() => {
        loadINSAssignments();
    }, []);
    const loadINSAssignments = async () => {
        try {
            let data = {

            };
            const res = await getINSAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="data-table mt-2">
                    <TabView>
                        <TabPanel header={t('paid')} leftIcon={<span className="material-symbols-outlined mx-2">new_releases</span>}>
                            <RequestsPaidForInst assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('rejected')} leftIcon={<span className="material-symbols-outlined mx-2">cancel</span>}>
                            <RequestsWithAnsRejected assignments={assignments} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default InsCompletedRequets;