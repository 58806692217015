import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
const FromInstructors = (props) => {
    const { t } = useTranslation();
    const supportTickets = props.tickets.filter((s)=> s.user_role ==="teacher");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    return (
        <div className="">
            <DataTable
                value={supportTickets}
                paginator
                rows={10}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[10, 25, 50]}
                dataKey="id"
                selectionMode="checkbox"
                filterDisplay="menu"
                emptyMessage={t('data_not_available')}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                filters={filters}
                header={
                    <div className='row'>
                        <div className="col-md-6">
                            <input className='form-control'
                                placeholder={t('search')}
                                onChange={(e) => {
                                    let _filters = { ...filters };
                                    _filters['global'].value = e.target.value;
                                    setFilters(_filters);
                                }}
                            />
                        </div>
                    </div>
                }
            >
                <Column
                    field="id"
                    header={t('st_id')}
                    sortable
                    body={(row) => {
                        return (
                            <span>{'ST_00'}{row.id}</span>
                        )
                    }}
                />
                <Column
                    field="st_title"
                    header={t('st_title')}
                    sortable
                />
                <Column
                    field="st_type"
                    header={t('st_type')}
                    body={(row) => {
                        return (
                            <span>{t(row.st_type)}</span>
                        )
                    }}
                    sortable
                />
                <Column
                    field="hr_id"
                    header={t('hr_id')}
                    sortable
                />
                <Column
                    field="user_id"
                    header={t('user')}
                    sortable
                    body={(row) => {
                        return (
                            <span>{'US_00'}{row.user_id}</span>
                        )
                    }}
                />
                <Column
                    field="st_status"
                    header={t('status')}
                    sortable
                />
                <Column
                    field="created_at"
                    header={t('created_at')}
                    sortable
                />
                <Column
                    header={t('actions')}
                    body={(row) => {
                        return (
                            <div className='d-flex'>
                                <Link
                                    to="/support-tickets/view"
                                    state={JSON.stringify(row)}
                                    className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                    <span className="material-symbols-outlined">
                                        visibility
                                    </span>
                                </Link>
                            </div>
                        )
                    }}
                />
            </DataTable>
        </div>
    );
}
export default FromInstructors;