import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const StudentMenu = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');
    const sizeToggler = useSelector((state) => state.app.showSidemenu);
    useEffect(() => {
        const path = location.pathname;
        setActivePath(path);
    }, [location.pathname, sizeToggler]);

    return (


        <div>
            <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                <Link to="/student/assignments">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            menu_book
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('help_requests')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('quotes') ? 'active' : ''}`}>
                <Link to="/student/quotes">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            assignment
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('received_quotes')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('wallet') ? 'active' : ''}`}>
                <Link to="/student/wallet">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            wallet
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('wallet')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('payouts') ? 'active' : ''}`}>
                <Link to="/student/payouts">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            send
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('pay_outs')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('support') ? 'active' : ''}`}>
                <Link to="/support-tickets">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            help
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('support_tickets')}
                        </span>
                    </span>
                </Link>
            </li>
            <li className={`app-sidemenu-bar-item ${activePath.includes('instructors') ? 'active' : ''}`}>
                <Link to="/instructors/all">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            person
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('instructors')}
                        </span>
                    </span>
                </Link>
            </li>
        </div>
    );
};

export default StudentMenu;
