import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { storeSubject, getSubjects, updateSubject, getSubjectCategories } from './../../apis/services';
import { Modal } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { InputSwitch } from "primereact/inputswitch";
import { setShowDeleteDialog } from '../../redux/reducer';
import DeleteModalContent from '../../commons/DeleteModalContent';
import { getTimeStamp } from '../../helpers/helpers';
import { Link } from 'react-router-dom';
import Select from 'react-select';
const Subjects = () => {
    const { t } = useTranslation();
    const store = useStore();
    const [subjects, setSubjects] = useState([]);
    const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);
    const [showEditSubjectModal, setShowEditSubjectModal] = useState({ show: false, item: '' });
    useEffect(() => {
        loadSubjects();
    }, []);
    const loadSubjects = async () => {
        try {
            const res = await getSubjects();
            setSubjects(res.data.subjects);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="d-flex jcsb">
                    <div className='mt-2 mb-2'>
                        <h4>{t('manage_subjects')}</h4>
                    </div>
                    <div className='p-2 d-flex '>
                        <Button className='p-btn'
                            onClick={() => {
                                setShowAddSubjectModal(true);
                            }}
                        > {t('add_subject')}</Button>
                        <Link className='link-btn mx-2' to='/subjects/categories'> {t('subject_categories')}</Link>
                    </div>
                </div>
                <Modal
                    show={showAddSubjectModal}
                >
                    <div className='p-4'>
                        <AddSubject
                            submit={() => {
                                setShowAddSubjectModal(false);
                                loadSubjects();
                            }}
                            cancel={() => {
                                setShowAddSubjectModal(false);
                            }}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showEditSubjectModal.show}
                >
                    <div className='p-4'>
                        <EditSubject
                            submit={() => {
                                setShowEditSubjectModal({ show: false, item: '' });
                                loadSubjects();
                            }}
                            cancel={() => {
                                setShowEditSubjectModal({ show: false, item: '' });
                            }}
                            item={showEditSubjectModal.item}
                        />
                    </div>
                </Modal>
                {

                }
                <DeleteModalContent
                    reload={() => {
                        loadSubjects();
                    }}
                />
                <div className="data-table mt-2">
                    <DataTable
                        value={subjects}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >

                        <Column
                            field="name"
                            header={t('subject')}
                            sortable
                        />
                        <Column
                            field="code"
                            header={t('code')}
                            sortable
                        />
                        <Column
                            field="s_cat"
                            header={t('category')}
                            sortable
                        />
                        <Column
                            field="description"
                            header={t('description')}
                            sortable
                        />

                        <Column
                            header={t('status')}
                            sortField='status'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.status === 1 ? true : false} />
                                )
                            }}
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Button
                                            onClick={(e) => {
                                                setShowEditSubjectModal({ show: true, item: row });
                                            }}
                                            className='icon-btn mx-1' severity='primary' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                store.dispatch(setShowDeleteDialog({ show: true, url: '/subjects/delete/' + row.id }))
                                            }}
                                            className='icon-btn mx-1' severity='danger' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );

}

const AddSubject = (props) => {
    const { t } = useTranslation();
    const [subjectTitle, setSubjectTitle] = useState();
    const [categories, setCategories] = useState([]);
    const [category, setyCategory] = useState();
    const [status, setStatus] = useState(true);
    const handleAddSubject = async () => {
        try {
            let data = {
                name: subjectTitle,
                code: subjectTitle,
                description: subjectTitle,
                category: category.id,
                status: status
            };
            await storeSubject(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        loadSubjectCategories();
    }, []);
    const loadSubjectCategories = async () => {
        try {
            let params = {
                active: 1,
            }
            const res = await getSubjectCategories(params);
            setCategories(res.data.subjectCategories);
        } catch (error) {

        }

    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleAddSubject();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('add_subject')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="subjectTitle" className='required mb-1' >{t('name')}</label>
                        <input type="text" id="subjectTitle" className='form-control' required
                            value={subjectTitle}
                            onChange={(e) => { setSubjectTitle(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="category" className='required mb-1' >{t('category')}</label>
                        <Select
                            options={categories}
                            value={category}
                            getOptionValue={(c) => c.id}
                            getOptionLabel={(c) => c.name}
                            onChange={(e) => {
                                setyCategory(e);
                            }}
                            placeholder={t('select')}
                        />
                    </div>
                </div>

                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
}
const EditSubject = (props) => {
    const { t } = useTranslation();
    const item = props.item;
    const [subjectTitle, setSubjectTitle] = useState(item.name);
    const [categories, setCategories] = useState([]);
    const [category, setyCategory] = useState();
    const [status, setStatus] = useState(item.status === 1 ? true : false);
    useEffect(() => {
        loadSubjectCategories();
    }, []);
    const loadSubjectCategories = async () => {
        try {
            let params = {
                active: 1,
            }
            const res = await getSubjectCategories(params);
            const cat = res.data.subjectCategories.filter((i) => i.id === item.category);
            setyCategory(cat[0]);
            setCategories(res.data.subjectCategories);
        } catch (error) {

        }

    }
    const handleEditSubject = async () => {
        try {
            let data = {
                id: item.id,
                name: subjectTitle,
                code: subjectTitle,
                description: subjectTitle,
                category: category.id,
                status: status
            };
            await updateSubject(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleEditSubject();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('edit_subject')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="subjectTitle" className='required mb-1' >{t('name')}</label>
                        <input type="text" id="subjectTitle" className='form-control' required
                            value={subjectTitle}
                            onChange={(e) => { setSubjectTitle(e.target.value) }}
                        />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="category" className='required mb-1' >{t('category')}</label>
                        <Select
                            options={categories}
                            value={category}
                            getOptionValue={(c) => c.id}
                            getOptionLabel={(c) => c.name}
                            onChange={(e) => {
                                setyCategory(e);
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('update')}</Button>
                </div>
            </div>
        </form>
    );
}
export default Subjects;