import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startLogin, getUserData } from './../../apis/services';
import { setUserLoggedStatus, setUser } from './../../redux/reducer';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import usePWAInstallPrompt from '../../routing/usePWAInstallPrompt';
import { Password } from 'primereact/password';

const Login = () => {
    const { t } = useTranslation();
    const store = useStore();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const toast = useRef(null);
    const { installable, installPWA } = usePWAInstallPrompt();
    const lang = localStorage.getItem('lang') || 'en';
    const handleLogin = async () => {
        try {
            setLoading(true);
            let data = {
                email: username,
                password: password
            }
            const res = await startLogin(data);
            localStorage.setItem('_jwt', res.data.access_token);
            const userData = await getUserData();
            console.log(userData);
            if (userData.data.code === 200) {
                store.dispatch(setUser(userData.data));
                store.dispatch(setUserLoggedStatus(true));
            }else{
                toast.current.show({ severity: 'error', summary: t('request_failed'), detail: t('user_suspended'), life: 3000 }); 
            }

            setLoading(false);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: t('request_failed'), detail: t('bad_credentials'), life: 3000 });
            setLoading(false);
        }
    }
    const updateAppLang = (newLang) => {
        localStorage.setItem('lang', newLang);
        window.location.reload();
    }
    return (
        <div className="container-fluid">
            <Toast ref={toast}
                severity="danger"
            />
            <div className='row'>
                <div className="col-lg-6 col-md-6 d-none d-lg-block d-md-block p-0">
                    <div className='vh-center bg-img-vhicle'>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 d-lg-block d-md-block">
                    <div className="login-container">
                        <div className="text-center mt-5">
                            <img src="/logo-w.png" alt=""
                                style={{
                                    width: "10rem"
                                }}
                            />
                            <h3 className='mt-4'>TA-PAL</h3>
                            <p className="title">{t('login_title')}</p>
                        </div>
                        <div className='mt-3 padding-lg-4 lg-padding-4 p-lg-5 p-3'>
                            <form action="" method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleLogin();
                                }}
                            >
                                <div className="form-group">
                                    <label htmlFor="username" className='required mb-1'>{t('email')}</label>
                                    <input type="text" name="username" id="username" className='form-control' required
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                        value={username}
                                    />
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="password" className='required mb-1'>{t('password')}</label>
                                    <Password name="password" id="password" className='pr-input' toggleMask required
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        value={password}
                                    />
                                </div>
                                <div className="mt-3 d-flex aic flex-wrap">
                                    <Button label={t("login")} loading={loading} type='submit' className='p-btn mx-1 mb-1' id='loginButton' />
                                    {
                                        installable &&
                                        <Button label={t("install_app")} loading={loading} type='button' className='p-btn mx-1 mb-1' id='loginButton'
                                            onClick={() => {
                                                installPWA();
                                            }}
                                        />
                                    }

                                </div>
                            </form>
                            <div className="col-12">
                                <div className="d-flex jcsb mt-3">
                                    <Link to="/forgot/password" className='m-2'>{t('forgot_password')}</Link>
                                    <Link to="/regsiter/student" className='m-2'>{t('student_register')}</Link>
                                    <Link to="/regsiter/instructor" className='m-2'>{t('instructor_register')}</Link>
                                    <Link to=""
                                        onClick={() => {
                                            updateAppLang(lang === "en" ? 'ar' : 'en');
                                        }}
                                        className='m-2'>{lang === "en" ? 'عربي' : 'English'}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;