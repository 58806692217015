import React from 'react';
const INSHome = () => {
    const lang = localStorage.getItem('lang') || 'en';
    return (
        <div className="">
            <div className="card p-4">
                {
                    lang === 'en' &&
                    <div className='en-content'>
                        <h4><strong>Instructions for Instructor Personnel</strong></h4>
                        <hr />
                        <p>
                            Below are the key rules, instructions, and reminders to ensure a professional and secure experience for both you and the students. Please review these carefully, as they are critical to maintaining the platform’s integrity and success.Below are the key rules, instructions, and reminders to ensure a professional and secure experience for both you and the students. Please review these carefully, as they are critical to maintaining the platform’s integrity and success.
                        </p>
                        <p>
                            <strong>
                                1. Instructions for Using TA-Pal
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Review Student Requests:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Check new requests carefully to ensure they align with your expertise.</span>
                                    </li>
                                    <li>
                                        <span>Only submit quotes for requests you can fulfill within the specified timeframe</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Provide Clear and Educational Responses:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Ensure your responses are comprehensive, accurate, and easy for students to understand.</span>
                                    </li>
                                    <li>
                                        <span>Focus on guiding students through problem-solving processes rather than providing direct answers.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Maintain Professionalism::</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Communicate respectfully and professionally in all interactions.</span>
                                    </li>
                                    <li>
                                        <span>Uphold educational integrity by avoiding plagiarism or unethical practices.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                2. Key Rules to Follow
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Anonymity and Privacy:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Do not share your personal information (e.g., name, email, phone number) or request personal information from students.</span>
                                    </li>
                                    <li>
                                        <span>All interactions must occur exclusively through TA-Pal’s platform.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Prohibited Activities:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Avoid offering or accepting payments outside the platform.</span>
                                    </li>
                                    <li>
                                        <span>Do not provide answers to exams, quizzes, or assignments in violation of academic integrity policies.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Adherence to Deadlines:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Honor the deadlines agreed upon with students. If unforeseen delays occur, communicate promptly through the platform</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Quality and Clarity:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Submit responses in clear, professional language, supported by relevant examples or explanations.</span>
                                    </li>
                                    <li>
                                        <span>Ensure that your responses align with the student’s request and are free of errors.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                3. Reminders for a Positive Experience
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>Focus on Learning:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Your goal is to empower students to understand concepts and solve problems independently..</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Respect Platform Rules:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Any violation of TA-Pal’s rules may result in suspension or removal from the platform.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Build Your Reputation:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>High-quality responses and positive ratings from students will enhance your visibility and opportunities on the platform.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>Monitor Your Earnings:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>Keep track of your completed tasks and earnings directly from your dashboard. Payments are securely processed upon student approval.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <h4>Support and Assistance</h4>
                            If you have any questions or require assistance, please contact our support team at <a href="mailto:info@ta-pal.com">info@ta-pal.com</a>.

                            Thank you for being part of TA-Pal. Your expertise helps students succeed while fostering a secure and professional educational environment.

                        </p>
                        <p>By diligently following these guidelines, you will help ensure that the TA-Pal platform remains a secure, anonymous, and professional space for academic assistance. If you encounter any unclear situations, escalate them to management for review.</p>
                    </div>
                }
                {
                    lang === 'ar' &&
                    <div className='ar-content'>
                        <h4><strong>
                            مرحبًا بك في لوحة التحكم للمدرسين في TA-Pal
                        </strong></h4>
                        <hr />
                        <p>فيما يلي القواعد، التعليمات، والتذكيرات الأساسية لضمان تجربة احترافية وآمنة لك وللطلاب. يُرجى مراجعتها بعناية، حيث إنها ضرورية للحفاظ على نزاهة ونجاح المنصة.</p>

                        <p>
                            <strong>
                                تعليمات استخدام TA-Pal
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>مراجعة طلبات الطلاب</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>تحقق بعناية من الطلبات الجديدة للتأكد من توافقها مع خبراتك</span>
                                    </li>
                                    <li>
                                        <span>قدم عروضًا فقط للطلبات التي يمكنك تلبيتها ضمن الإطار الزمني المحدد</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>تقديم ردود تعليمية وواضحة</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>تأكد من أن الردود شاملة، دقيقة، وسهلة الفهم للطلاب</span>
                                    </li>
                                    <li>
                                        <span>ركز على إرشاد الطلاب في عمليات حل المشكلات بدلاً من تقديم الإجابات المباشرة.</span>

                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>الحفاظ على الاحترافية</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>تواصل باحترام واحترافية في جميع التفاعلات</span>
                                    </li>
                                    <li>
                                        <span>التزم بالنزاهة الأكاديمية وتجنب أي ممارسات غير أخلاقية</span>

                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>القواعد الأساسية التي يجب اتباعها</strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>السرية والخصوصية:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>لا تشارك معلوماتك الشخصية (مثل الاسم أو البريد الإلكتروني أو رقم الهاتف) ولا تطلب معلومات شخصية من الطلاب</span>
                                    </li>
                                    <li>
                                        <span>	يجب أن تتم جميع التفاعلات حصريًا عبر منصة TA-Pal</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>الأنشطة المحظورة:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>تجنب تقديم أو قبول أي مدفوعات خارج المنصة</span>
                                    </li>
                                    <li>
                                        <span>لا تقدم إجابات للاختبارات أو الواجبات التي تنتهك سياسات النزاهة الأكاديمية</span>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>الالتزام بالمواعيد النهائية</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>التزم بالمواعيد النهائية المتفق عليها مع الطلاب. إذا حدثت أي تأخيرات غير متوقعة، قم بالتواصل فورًا عبر المنصة.</span>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>الجودة والوضوح:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>قدم ردودًا بلغة واضحة واحترافية مدعومة بأمثلة أو شروحات ذات صلة.</span>
                                    </li>
                                    <li>
                                        <span>تأكد من أن الردود تتماشى مع طلب الطالب وخالية من الأخطاء</span>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>
                                تذكيرات لضمان تجربة إيجابية
                            </strong>
                        </p>
                        <ul className='mx-4'>
                            <li>
                                <p className='mt-2'>
                                    <strong>ركز على التعلم:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>هدفك هو تمكين الطلاب من فهم المفاهيم وحل المشكلات بشكل مستقل.</span>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>احترام قواعد المنصة:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>قد يؤدي أي انتهاك لقواعد TA-Pal إلى تعليق أو إزالة حسابك من المنصة.</span>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>بناء سمعتك:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>الردود عالية الجودة والتقييمات الإيجابية من الطلاب ستعزز من ظهورك وفرصك على المنصة.</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p className='mt-2'>
                                    <strong>راقب أرباحك:</strong>
                                </p>
                                <ul>
                                    <li>
                                        <span>تابع المهام المكتملة وأرباحك مباشرة من لوحة التحكم. تتم معالجة المدفوعات بأمان عند موافقة الطالب.</span>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                        <p>
                            <h4>الدعم والمساعدة</h4>
                            إذا كان لديك أي استفسارات أو تحتاج إلى مساعدة، يرجى التواصل مع فريق الدعم على <a href="mailto:info@ta-pal.com">info@ta-pal.com</a>.
                            شكرًا لكونك جزءًا من TA-Pal. خبراتك تسهم في نجاح الطلاب وتدعم بيئة تعليمية آمنة ومهنية
                        </p>
                    </div>
                }
            </div>
        </div>
    );
}
export default INSHome;