import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getStudAssignmentQuotes } from '../../apis/services';
import { TabView, TabPanel } from 'primereact/tabview';
import StudentRecivedQuotes from './StudentRecivedQuotes';
import StudAcceptedQuotes from './StudAcceptedQuotes';
const StudentQuotes = () => {
    const { t } = useTranslation();
    const [hrQuotes, setHrQuotes] = useState([]);

    useEffect(() => {
        loadINSAssignmentQuotes();
    }, []);
    const loadINSAssignmentQuotes = async () => {
        try {
            const res = await getStudAssignmentQuotes();
            setHrQuotes(res.data.quotes);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card">
                <TabView>
                    <TabPanel header={t('quotes_received')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">schedule</span> }}>
                            <StudentRecivedQuotes hrQuotes={hrQuotes} />
                        </TabPanel>
                        <TabPanel header={t('accepted_quotes')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">library_add_check</span> }}>
                            <StudAcceptedQuotes hrQuotes={hrQuotes} />
                        </TabPanel>

                </TabView>
            </div>
        </div>
    );
}
export default StudentQuotes;