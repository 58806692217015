import React, { useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Rating } from 'primereact/rating';
import { Toast } from 'primereact/toast';
import { submitHRInstRating, approveHRAnswer, rejectHelpRequest } from '../../../apis/services';
import { Modal } from 'react-bootstrap';
import store from '../../../redux/store';
import Select from 'react-select';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const ViewHRAnswers = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const files = JSON.parse(location.state);
    const preview = JSON.parse(files.preview);
    const answers = JSON.parse(files.answers);
    const ans_desc = files.ans_desc;
    const isRated = files.rating;
    const ids = files.ids;
    const { t } = useTranslation();
    const [rating, setRating] = useState(3);
    const [showRejection, setShowRejection] = useState(false);
    const [rejectionReason, setRejectionReason] = useState();
    const [rejectionComments, setRejectionComments] = useState();
    const toastRef = useRef();
    const [showRating, setShowRating] = useState(isRated === null ? true : false);
    const user = store.getState().app.user.user;
    const rejectionReasons = [
        {
            value: 'personal_data',
            label: t('personal_data'),
        },
        {
            value: 'un_clear_files',
            label: t('un_clear_files'),
        },
        // {
        //     value: 'stud_not_serious',
        //     label: t('stud_not_serious'),
        // },
        {
            value: 'due_date_over',
            label: t('due_date_over'),

        },
        {
            value: 'ans_not_matching',
            label: t('ans_not_matching'),
        },
        {
            value: 'others',
            label: t('others'),
        }
    ];

    const handleRateHRAnswers = async () => {
        try {
            let data = {
                rating: rating,
                hw_id: ids.hr,
                ins_id: ids.ins,
            }
            await submitHRInstRating(data);
            toastRef.current.show({ severity: 'success', summary: t('rating_submitted'), detail: t('thanks_for_submitting_rating'), life: 3000 });
            setShowRating(false);
        } catch (error) {

        }
    }
    const handelApproveAnswer = async () => {
        try {
            let data = {
                hw_id: ids.hr,
            }
            await approveHRAnswer(data);
            navigate(-1);
        } catch (error) {
            console.log(error);

        }
    }
    const handleRejectHW = async () => {
        try {
            let data = {
                rejectionReason: rejectionReason.value,
                rejectionComments: rejectionComments,
                hw_id: ids.hr,
                role: 'teacher',
                ins_id: ids.ins,
            }
            await rejectHelpRequest(data);
            setShowRejection(false);
            navigate(-1);
        } catch (error) {

        }
    }
    return (
        <div className="glass-card p-3">
            <Toast ref={toastRef} />
            <Modal
                show={showRejection}
            >
                <div className="p-3">
                    <div className="d-flex jcsb">
                        <h4>{t('reject_help_request')}</h4>
                        <Button className='icon-btn'
                            onClick={() => {
                                setShowRejection(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <div className="p-2">
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleRejectHW();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="reason" className='mb-1 required'>{t('rejection_reason')}</label>
                                <Select className='pr-input' required
                                    options={rejectionReasons}
                                    placeholder={t('select')}
                                    onChange={(e) => {
                                        setRejectionReason(e)
                                    }}
                                    value={rejectionReason}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="comments" className='mb-1 required'>{t('comments')}</label>
                                <textarea name="comments" id="comments" className='form-control'
                                    value={rejectionComments}
                                    onChange={(e) => {
                                        setRejectionComments(e.target.value);
                                    }}
                                    required
                                ></textarea>
                            </div>
                            <div className="form-group mt-3">
                                <Button label={t('reject')} className='p-btn ' />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <h4>{t('help_request_answers')}</h4>
            <div className="row">
                <div className="col-md-4">
                    <div className="card p-2">
                        <h6>{t('total_files')}</h6>
                        <h5>{answers.length}</h5>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="card p-2">
                        <h6>{t('answer_description')}</h6>
                        <h5>{ans_desc}</h5>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="card m-2 p-2">
                        <img src={preview.uri} alt=""
                            style={{
                                width: '100%'
                            }}
                        />
                    </div>
                    {
                        (showRating && user.role === "student") &&
                        <div className="card p-4">
                            <h6 className='mb-3'>{t('please_rate_the_answers')}</h6>
                            <Rating cancel={false} value={rating}
                                offIcon={
                                    <span className="material-symbols-outlined"
                                        style={{
                                            fontSize: '3rem'
                                        }}
                                    >
                                        star
                                    </span>
                                }
                                onIcon={
                                    <span className="material-symbols-outlined"
                                        style={{
                                            color: "red",
                                            fontSize: '3rem'
                                        }}
                                    >
                                        stars
                                    </span>
                                }
                                onChange={(e) => {
                                    setRating(e.value);
                                }}
                            />
                            <Button label={t('submit_rating')} className='mt-3 p-btn'
                                onClick={() => {
                                    handleRateHRAnswers();
                                }}
                            />
                        </div>
                    }
                    {

                        (user.role === "qa" && files.ans_approved === 0 && files.rejected === 0) &&
                        <div>
                            <Button label={t('approve_ans')} className='p-btn'
                                onClick={() => {
                                    handelApproveAnswer()
                                }}
                            />
                            <Button label={t('reject')} severity='danger' className='mx-2 rounded-btn'
                                onClick={() => {
                                    setShowRejection(true);
                                }}
                            />
                        </div>
                    }
                    {
                        user.role === "teacher" && files.ans_approved === 0 && files.rejected === 1 &&
                        <div className='mt-4 mb-3'>
                            <Link to="/assignments/answers/resubmit" 
                                state={ids}
                            className='link-btn'>{t('resubmit_answers')}</Link>
                        </div>
                    }
                </div>
                <div className="col-md-7">
                    <div className="card m-2 p-3">
                        <Carousel
                            className='file-sliders'
                            showArrows
                            infiniteLoop
                        >
                            {
                                answers.map((a, index) => {
                                    return (
                                        <div key={index} className='p-2'>
                                            {
                                                (a.type === "image/png" || a.type === "image/jpeg") &&
                                                <a href={a.uri} target="_blank" rel="noopener noreferrer"
                                                    style={{
                                                        display: "block",
                                                    }}
                                                >
                                                    <img src={a.uri}
                                                        style={{
                                                            maxHeight: 400,
                                                            maxWidth: 400,
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            alignSelf: 'center'
                                                        }}
                                                    />
                                                </a>
                                            }
                                            {
                                                (a.type !== "image/png" && a.type !== "image/jpeg") &&
                                                <a href={a.uri} target="_blank" rel="noopener noreferrer">
                                                    <div
                                                        style={{
                                                            border: "2px dotted #ccc",
                                                            padding: '5rem',
                                                            margin: 50
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined"
                                                            style={{
                                                                fontSize: 80,
                                                            }}
                                                        >
                                                            attach_file
                                                        </span>
                                                    </div>

                                                </a>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewHRAnswers;