import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getStudAssignments } from '../../apis/services';
import { Link } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import StudReqsUnderReview from './tabs/stud/StudReqsUnderReview';
import AnsweredRequests from './tabs/stud/AnsweredRequests';
import StudReqQCPassed from './tabs/stud/StudReqQCPassed';
import StudReqRejectedByQA from './tabs/stud/StudReqRejectedByQA';
import PaidHRs from './tabs/PaidHRs';
const StudentAssignments = () => {
    const { t } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    useEffect(() => {
        loadStudAssignments();
    }, []);
    const loadStudAssignments = async () => {
        try {
            let data = {

            };
            const res = await getStudAssignments(data);
            setAssignments(res.data.homeworks);
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="glass-card">
                <div className="d-flex jcsb p-3">
                    <div className='mt-2 mb-2'>
                        <h4>{t('manage_help_requests')}</h4>
                        <h6>{t('click_ad_hr_to_create_hr')}</h6>
                    </div>
                    <div className='p-2 mb-2'>
                        <Link className='link-btn' to='/student/add/assignments'>{t('add_hr')}</Link>
                    </div>
                </div>
                <div className="data-table mt-2">
                <TabView>
                        <TabPanel header={t('under_review')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">schedule</span> }}>
                            <StudReqsUnderReview assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('qc_passed')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">library_add_check</span> }}>
                            <StudReqQCPassed assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('answered')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">check_circle</span> }}>
                            <AnsweredRequests assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('paid')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">new_releases</span> }}>
                            <PaidHRs assignments={assignments} />
                        </TabPanel>
                        <TabPanel header={t('qc_rejected')} leftIcon={() => { return <span className="material-symbols-outlined mx-2">cancel</span> }}>
                            <StudReqRejectedByQA assignments={assignments} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
}
export default StudentAssignments;